import axios from 'axios';
import { changeDpiDataUrl } from 'changedpi';
import { saveAs } from 'file-saver';
import {
  $attr,
  $closest,
  $data,
  $delegate,
  $find,
  $findAll,
  $is,
  $setAttr,
  $setCss,
  $toggleClass,
  $trigger,
} from 'fxdom/es';
import {
  compactC,
  delay,
  each,
  every,
  filter,
  filterC,
  find,
  go,
  ifElse,
  join,
  map,
  mapC,
  pipe,
  pluck,
  reject,
  sel,
  some,
  sortByDesc,
  strMap,
  sumBy,
  takeAllC,
  tap,
  uniqBy,
} from 'fxjs/es';
import onScan from 'onscan.js';
import { createCanvasElement } from '../../../../../modules/Canvas/S/util.js';
import { MPS_SMS_TYPE } from '../../../../../modules/Creator/Sms/S/constant.js';
import { DfAutoImageWorkF } from '../../../../../modules/Df/AutoImageWork/F/Function/module/DfAutoImageWorkF.js';
import { DfAutoImageWorkConstantS } from '../../../../../modules/Df/AutoImageWork/S/Constant/module/DfAutoImageWorkConstantS.js';
import { DfImageEditorF } from '../../../../../modules/Df/ImageEditor/F/Function/module/DfImageEditorF.js';
import { openImageEditorFrame } from '../../../../../modules/Df/ImageEditor/F/Mui/openImageEditorFrame.js';
import { baseProjectionDetailSubProjectionsTab } from '../../../../../modules/Df/Projection/Detail/F/baseProjectionDetailSubProjectionsTab.js';
import {
  cjTrackingFromShippingId,
  handleDoubleChecker,
  makeDfProjectionShipEvent,
  makeDfProjectionSideBarEvent,
} from '../../../../../modules/Df/Projection/List/F/event.js';
import {
  makeDfProjectionListUpdate,
  setDfProjectionListUpdateIsStop,
} from '../../../../../modules/Df/Projection/List/F/fs.js';
import {
  getProjectionLabelData,
  printProjectionLabel,
} from '../../../../../modules/Df/Projection/List/F/label.js';
import { makeDfProjectionShipItemsHtml } from '../../../../../modules/Df/Projection/List/F/shipItemTmpl.js';
import {
  makeDfProjectionListBpStocksHtml,
  makeDfProjectionListUpCItemsHtml,
  makeUpTitle,
  mergeTplUpsForPrj,
} from '../../../../../modules/Df/Projection/List/F/tmpl.js';
import { eventSmsImageUpload } from '../../../../../modules/Df/Sms/Df/F/event.js';
import { msgSelectBoxEvent } from '../../../../../modules/Df/Sms/Df/F/msg_select_box.js';
import {
  makeSmsProjectionItemHtml,
  makeSmsSendFormDFHtml,
  makeSmsSendReceiveItemDFHtml,
} from '../../../../../modules/Df/Sms/Df/S/tmpl.js';
import { scannerActionEvents } from '../../../../../modules/Df/Stock/ScanControl/F/scanEvents.js';
import { GoodsTypeS } from '../../../../../modules/GoodsType/S/Function/module/GoodsTypeS.js';
import { downloadAutoPhonecasePrintImg } from '../../../../../modules/Maker/F/downloadAutoPhonecasePrintImg.js';
import { makeOnlyDesignFaceCanvasByPrintArea } from '../../../../../modules/Maker/F/draw_product_faces.js';
import {
  downloadFileForPDland,
  downloadForPdLand,
} from '../../../../../modules/Maker/F/HalfAutoTemplate/fs.js';
import { unsetFcanvass } from '../../../../../modules/Maker/F/mp_maker.js';
import {
  getPrintableImageEditableSource,
  makePrintableComposedImage,
  makeResizedPrintableComposedImage,
} from '../../../../../modules/Maker/F/printable_compose_images.js';
import { is_load_font_important } from '../../../../../modules/Maker/F/text.js';
import {
  makeImageFromUrl,
  setPfColllaboTypeBpsId,
  unsetPfCollaboTypeBpsId,
} from '../../../../../modules/Maker/F/util.js';
import { makeTitleByKeys } from '../../../../../modules/NewMaker/PrintResult/F/Function/makePxToResultPx.js';
import { NewMakerPrintResultF } from '../../../../../modules/NewMaker/PrintResult/F/Function/module/NewMakerPrintResultF.js';
import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';
import { checkPhoneNumberSms } from '../../../../../modules/Sms/Fs/S/fs.js';
import { NESS_SMS_TYPE } from '../../../../../modules/Sms/Ness/B/constant.js';
import { SVGEditorUtilF } from '../../../../../modules/SVGEditor/Util/F/Function/module/SVGEditorUtilF.js';
import { UtilS } from '../../../../../modules/Util/S/Function/module/UtilS.js';
import { legacyHtml } from '../../../../../modules/Util/S/Function/util.js';
import { langToDfWord, rowTo_en } from '../../../../../modules/Util/S/LangAndCollaboType.js';
import { VectorEditorConstantS } from '../../../../../modules/VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import {
  calculatePrintableFileShiboriCm,
  calculateShiboriForDesign,
} from '../../../../../modules/Df/Projection/List/F/shibori.js';
import JSZip from 'jszip';
import { workOrderSheetEventHandler } from './WorkOrderSheet/eventHandler.js';
import { BpOptionConstantS } from '../../../../../modules/BpOption/S/Constant/module/BpOptionConstantS.js';
import Swal from 'sweetalert2';
import { downloadUrl } from '../../../../../modules/Df/ImageEditor/F/Function/helpers.file.js';
import { UtilStringS } from '../../../../../modules/Util/String/S/Function/module/UtilStringS.js';
import { DfWaybillF } from '../../../../../modules/Df/Waybill/F/Function/module/DfWaybillF.js';
import { printShopChildrenWaybills } from '../../../../../modules/Df/Waybill/F/Function/print_shop_children_waybills.js';
import { DfInhouseF } from '../../../../../modules/Df/Inhouse/F/Function/module/DfInhouseF.js';
import { DfBpLabelF } from '../../../../../modules/Df/BpLabel/F/Function/module/DfBpLabelF.js';
import { printMpsUpsForNESS } from '../../../../../modules/Df/Projection/List/F/ness.js';
import { UtilAlertF } from '../../../../../modules/Util/Alert/F/Function/module/UtilAlertF.js';
import { MuiF } from '../../../../../modules/Mui/F/Function/module/MuiF.js';
import { DfFileIssueModalMuiF } from '../../../../../modules/Df/FileIssueModal/F/Mui/module/DfFileIssueModalMuiF.js';
import { DfLabelF } from '../../../../../modules/Df/Label/F/Function/module/DfLabelF.js';
import { UtilF } from '../../../../../modules/Util/F/Function/module/UtilF.js';
import { SimpleModalMuiF } from '../../../../../modules/SimpleModal/F/Mui/module/SimpleModalMuiF.js';
import { PdfF } from '../../../../../modules/Pdf/F/Function/module/PdfF.js';
import { DfLglHaezleF } from '../../../../../modules/Df/Lgl/Haezle/F/Function/module/DfLglHaezleF.js';
import format from 'date-fns/format/index.js';
import { STORE_PRODUCT_CUSTOM_LEVEL } from '../../../../../modules/Creator/Product/S/constant.js';

!(function (LF) {
  async function makeDataURLToBlack(url) {
    const image = await makeImageFromUrl(url);
    const canvas = await createCanvasElement({ width: image.width, height: image.height });
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.globalCompositeOperation = 'destination-in';
    ctx.drawImage(image, 0, 0);
    return canvas.toDataURL('image/png', 1);
  }

  const tumbler_bp_ids = [4902, 4903, 4905, 4979, 5045, 5074, 5075, 5076, 5273, 5373, 5476];
  const ring_bp_ids = [
    4444, 4445, 4449, 4452, 4451, 4447, 4692, 4703, 4554, 4450, 4448, 4446, 4702, 4556, 4555, 4553,
  ];
  const carved_bp_ids = tumbler_bp_ids.concat(ring_bp_ids);
  const init_select_press_type = _tap(function () {
    _go($('.select_press_type'), _each($.don_select));
  });

  const common_memo_pipe =
    (type) =>
    (...fns) =>
    ({ currentTarget }) => {
      const up_item = $.closest(currentTarget, `.up_item[has_memo_${type}]`);
      return _p.go(_p.mr(up_item, $.find1(up_item, `.cell.up_memo_${type}`), box.sel(up_item)), ...fns);
    };

  function render_products_tab(el_child) {
    return G.df.projection.detail.tab_reload(el_child);
  }

  const up_total_quantity = (up) =>
    _p.flatten(_p.map(up._.up_cs, (up_c) => _p.pluck(up_c._.up_c_ss, 'quantity'))).reduce((a, b) => a + b);

  const up_c_total_quantity = (up_c) =>
    _p.flatten(_p.map([up_c], (up_c) => _p.pluck(up_c._.up_c_ss, 'quantity'))).reduce((a, b) => a + b);

  $.frame.defn_frame({
    frame_name: 'projection.detail',
    page_name: 'projection.detail',
    el_id: 'projection_detail',
    el_class: 'projection projection_detail',
    always_remove: true,
    appending: function (el_frame) {
      $setAttr(['collabo_type', el_frame.frame_opt.collabo_type], el_frame);
      $setAttr(['projection_type', el_frame.frame_opt.projection_type], el_frame);
    },
    appended: function (tab_el) {
      go(
        tab_el,
        $delegate('click', '.don_tab button', () => {
          if (box.sel('df/projection/detail->projection->_->afreeca_orders->length') > 0) {
            return window.alert(
              `[경고]숲토어 주문은 변경하게되면 숲토어 데이터와 싱크가 맞지 않을 수 있습니다. 숲토어 운영 담당자분과 꼭 얘기 후 진행부탁드립니다.`,
            );
          }
        }),
        $delegate('click', '.projection_code_image', async ({ currentTarget }) => {
          const label_data = await getProjectionLabelData($attr('data-projection_id', currentTarget));
          if (label_data && label_data.length) {
            await printProjectionLabel(label_data);
          }
        }),
        $delegate('click', '.double_check', handleDoubleChecker),
        $delegate('click', '.shop-waybill-status[is_creator="false"]', async ({ currentTarget: ct }) => {
          const shipping_id = box.sel(ct)._.shippings[0].id;
          await cjTrackingFromShippingId({ shipping_id });
        }),
        $delegate('click', '.shop-waybill-status.merged_parent', async (e) => {
          const el = go(e.currentTarget, $closest('.projection_item'));
          const prj_id_data = $attr('_sel', el);
          const id_matcher = prj_id_data.match(/\d+/g);
          if (id_matcher != null && id_matcher.length) {
            const projection_id = id_matcher[0];
            await DfWaybillF.popupChildShippingStatusForShopByParent({ projection_id });
          }
        }),
        $delegate('click', '.shop-waybill-status.merged_child', async (e) => {
          const el = go(e.currentTarget, $closest('.projection_item'));
          const prj_id_data = $attr('_sel', el);
          const id_matcher = prj_id_data.match(/\d+/g);
          if (id_matcher != null && id_matcher.length) {
            const projection_id = id_matcher[0];
            await DfWaybillF.popupChildShippingStatusForShopByChild({ projection_id });
          }
        }),
        $delegate('click', '.save_lgl_express_for_merged_parent', async ({ currentTarget: ct }) => {
          const merged_parent_projection = go(ct, $closest('.projection_item'), box.sel);

          try {
            $.don_loader_start();
            const child_projections_oversea = (
              await axios.get(
                UtilS.makeApiUrl('/@api/projections/:parent_id/childs/oversea_express', {
                  parent_id: merged_parent_projection.id,
                }),
              )
            ).data;

            if (UtilS.isEmpty(child_projections_oversea)) {
              $.don_loader_end();
              $.alert('해외 배송용 주문서가 없습니다.');
              return;
            }

            const {
              _: { store },
            } = merged_parent_projection;

            await DfLglHaezleF.bulkDownloadLglHaezleForm({
              filename: `병합주문_${store.name}_${merged_parent_projection.id}_${format(
                new Date(),
                'yyyy_MM_dd',
              )}`,
              projections: child_projections_oversea,
            });

            $.don_loader_end();
          } catch (err) {
            console.error(err);
            $.don_loader_end();
            $.alert(UtilF.getErrMsg(err));
          }
        }),
        $delegate('click', '.print_children_waybills', async ({ currentTarget: ct }) => {
          const projection_item_el = go(ct, $closest('.projection_item '));
          const is_inhouse = $attr('is_inhouse', projection_item_el);

          if (is_inhouse === 'true') {
            $.alert('사내 배송은 개별건에 대해 운송장 출력을 하지 않습니다.');
            return;
          }

          const parent_projection_id =
            box.sel(ct)?.id ||
            go(projection_item_el, $find('.projection_code_image'))?.dataset?.projection_id;

          if (parent_projection_id == null) {
            $.alert('주문 번호를 가져올 수 없습니다.');
            return;
          }
          try {
            await printShopChildrenWaybills({ parent_projection_id });
            DfInhouseF.lottie_loader.end();
          } catch (err) {
            console.error(err);
            DfInhouseF.lottie_loader.end();
            $.alert(err.message);
          }
        }),
        $delegate('click', '.print_hanjin_inbound_orders_for_pod', async ({ currentTarget: ct }) => {
          DfInhouseF.lottie_loader.start();
          try {
            const projection = box.sel(ct);
            await printMpsUpsForNESS({ projection_id: projection.id });
            DfInhouseF.lottie_loader.end();
            await UtilAlertF.success({
              title: 'POD 한진 입고',
              msg: '한진 입고 후 반드시 한진입고 태스크를 완료해 주세요.',
              timer: 0,
            });
          } catch (err) {
            console.error(err);
            DfInhouseF.lottie_loader.end();

            await UtilAlertF.error({
              title: '오류 발생',
              msg: err.isAxiosError ? err.response.data : (err?.message ?? '개발팀 문의'),
            });
          }
        }),
      );
      !onScan.isAttachedTo(document) &&
        scannerActionEvents(document, ({ projection_id }) => {
          window.open(`${location.origin}/projection/detail/${projection_id}`, '_self');
        });
    },
  });

  $.frame.defn_page({
    page_name: 'projection.detail',
    appended: pipe(
      makeDfProjectionSideBarEvent,
      downloadForPdLand,
      $.on2(
        'click',
        '.projection_sidebar .projection_item .check, .projection_sidebar .projection_item .type_status',
        function (e) {
          const p = box.sel(e.currentTarget);
          if (p.merged_type == 'parent') {
            return G.df.projection.detail.open(p);
          } else {
            const el_don_frame = $.closest(e.currentTarget, '.don_frame');
            el_don_frame.frame_opt.projection_id = p.id;
            const don_tab = $.find1(el_don_frame, '.don_tab.is_show');
            $.remove_class(
              $.find1(el_don_frame, '.projection_sidebar .projection_item.selected'),
              'selected',
            );
            $.add_class($.closest(e.currentTarget, '.projection_item'), 'selected');
            return don_tab.tab_opt.showed(don_tab);
          }
        },
      ),
      $.on2('click', '.projection_sidebar .add_repress', async function (e) {
        const el_don_frame = $.closest(e.currentTarget, '.don_frame');
        const projection = box.sel($.find1(el_don_frame, '.projection_sidebar .projection_item'));
        await $.post('/@api/projection/add_repress', _p.omit(projection, '_'));
        const don_tab = $.find1(el_don_frame, '.don_tab.is_show');
        box.set('df/projection/detail->projection', {});
        return don_tab.tab_opt.showed(don_tab);
      }),
      $.on2('click', '.remove_repress', async function (e) {
        await $.post('/@api/projection/remove_repress', {
          id: box.sel('df/projection/detail->projection->id'),
        });
        const el_don_frame = $.closest(e.currentTarget, '.don_frame');
        $.trigger($.find1(el_don_frame, '.projection_sidebar .projection_item .check'), 'click');
      }),
      $.on('dragstart', '.title span[draggable="true"]', function (e) {
        const el_don_frame = $.closest(e.currentTarget, '.don_frame');
        $.add_class(el_don_frame, 'repress_dragging');
        box.set('df/projection/detail->dragging', {
          up_c: _p.omit(box.sel($.closest(e.currentTarget, '.up_c_item')), '_'),
        });
      }),
      $.on('dragover', '.projection_sidebar .projection_item[is_repress="true"] .droparea', function (e) {
        e.preventDefault();
      }),
      $.on('dragend', '.title span[draggable="true"]', function (e) {
        const el_don_frame = $.closest(e.currentTarget, '.don_frame');
        $.remove_class(el_don_frame, 'repress_dragging');
      }),
      $.on('dragenter', '.projection_sidebar .projection_item[is_repress="true"] .droparea', function (e) {
        e.preventDefault();
        $.add_class(e.currentTarget, 'dragenter');
      }),
      $.on('dragleave', '.projection_sidebar .projection_item[is_repress="true"] .droparea', function (e) {
        e.preventDefault();
        $.remove_class(e.currentTarget, 'dragenter');
      }),
      $.on2('drop', '.projection_sidebar .projection_item[is_repress="true"] .droparea', async function (e) {
        e.preventDefault();
        $.remove_class(e.currentTarget, 'dragenter');
        const { up_c } = box.sel('df/projection/detail->dragging');
        const projection = box.sel(e.currentTarget);
        const new_up = await $.post('/@api/projection/repress/add_up_c', {
          projection_id: projection.id,
          up_c,
        });
        if (!new_up) return $.alert('실패했습니다.');
        const is_cancel = !(await open_edit_sizes(new_up, new_up._.up_cs[0], null));
        if (is_cancel) {
          $.don_loader_start();
          await $.delete('/@api/projection/repress/up', { id: new_up.id });
          return $.don_loader_end();
        }
        box.set('df/projection/detail->projection', {});
        const el_don_frame = $.closest(e.currentTarget, '.don_frame');
        const don_tab = $.find1(el_don_frame, '.don_tab.is_show');
        return don_tab.tab_opt.showed(don_tab);
      }),
    ),
    tabs: [
      {
        tab_name: 'products',
        title: '이미지작업 / 인쇄설정 (운영팀)',
        template: () => '',
        hiding: G.df.projection.detail.tab_hiding,
        hided: G.df.projection.detail.tab_hided,
        showed: G.df.projection.detail.base_tab_showed(
          (p) => pug`
            .detail_product.prj.prj_item[_sel="df/projection/detail->projection"]
              .top_id
                a[href="/projection/detail/${p.id}" target="_blank"] ${`[${langToDfWord(p.lang)}]`} #${p.id}
                ${
                  p.is_repress
                    ? pug`
                  button[type="button"].remove_repress 재제작 주문서 삭제`
                    : ''
                }
                ${
                  !p.o_id
                    ? ''
                    : legacyHtml`
                        <div class="warning_message2" style="margin-left: 8px; top: -3px;">
                          <span>!</span>
                          <a
                            href="https://old_df.marpple.com/projections?search_projection_id=${p.o_id}"
                            target="_blank"
                            >구 주문서 ID #${p.o_id}</a
                          >
                        </div>
                      `
                }
              h2
                sp 이미지 작업 / 인쇄 설정 (운영팀전용)
                sp[style="${p.collabo_type == 'creator' ? 'display: none;' : ''}"]
                  button[type="button"].fill_all_printable 인쇄용 시안 모두 채우기
              ${
                p.special_request
                  ? `
                <div class="cell special_request">
                  <div class="body">고객 요청 사항 :<br>${UtilS.linkify(p.special_request || '')}</div>
                </div>
                `
                  : ''
              }
              .df_grid
                .all_ups.grid
                  .up_list.col[_sel="./_->ups"]
                    ${_p.go(
                      p._.ups,
                      p.is_repress ? _p.filter(up_total_quantity) : _p.idtt,
                      mergeTplUpsForPrj(p),
                      _p.sort_by((up) => (up_total_quantity(up) == 0 ? 1 : 0)),
                      sortByDesc(({ goods_type_id }) => {
                        return GoodsTypeS.isTpl(goods_type_id);
                      }),
                      _p.sum((up) => tmpl_up_item(up, p)),
                    )}
              .print_file_manager
                label.upload_all.btn 선택한 면 인쇄용 파일 업로드
                  input[type="file"]
                .btn.empty 인쇄방식만 지정하기
                .btn.make_all_auto_print_files[data-press_type_id="${
                  DfAutoImageWorkConstantS.DTP.press_type_id
                }"] 선택한 면 DTP 만들기
                .btn.make_all_auto_print_files[data-press_type_id="${
                  DfAutoImageWorkConstantS.DTF.press_type_id
                }"] 선택한 면 DTF 만들기
                button[type="button" name="all"] 모두
                ${_p.go(
                  p._.ups,
                  _p.map((up) => up._.up_cs),
                  _p.flatten,
                  _p.map((up_c) => up_c._.product_color.product_faces2.value),
                  _p.flatten,
                  _p.map('face_name'),
                  _p.uniq,
                  _p.sort_by((face_name) => {
                    return face_name == '앞면'
                      ? 1
                      : face_name == '뒷면'
                        ? 2
                        : face_name == '목뒤'
                          ? 3
                          : face_name == '왼팔'
                            ? 4
                            : face_name == '오른팔'
                              ? 5
                              : 6;
                  }),
                  _p.sum(
                    (face_name) => pug`
                    button[type="button" name="${face_name}"] 모든 ${face_name}`,
                  ),
                )}`,
          _p.pipe(G.mp.maker.draw_product_face_in_ups, init_select_press_type),
        ),
        appending: function (el_detail_product) {
          _go(
            el_detail_product,
            $.on('click', '.print_file_manager button', function (e) {
              const face_name = $.attr(e.currentTarget, 'name');
              const sel = face_name == 'all' ? '' : `[face_name="${face_name}"]`;
              const not_checked = $.find1(
                e.delegateTarget,
                `.up_item:not([quantity="0"]) .printable_files input[type="checkbox"]${sel}:not(:checked)`,
              );
              if (not_checked)
                _p.each(
                  $.find(
                    e.delegateTarget,
                    `.up_item:not([quantity="0"]) .printable_files input[type="checkbox"]${sel}`,
                  ),
                  (el) => (el.checked = true),
                );
              else
                _p.each(
                  $.find(
                    e.delegateTarget,
                    `.up_item:not([quantity="0"]) .printable_files input[type="checkbox"]${sel}`,
                  ),
                  (el) => (el.checked = false),
                );
            }),

            $.on2('click', '.print_file_manager .empty', async function (e) {
              const product_color_faces = _p.go(
                e.delegateTarget,
                $.find(`.printable_files input[type="checkbox"]:checked`),
                _p.map((checkbox) => {
                  const face_idx = _go(checkbox, $.closest('.product_face'), $.attr('idx'), parseInt);
                  const pc = _go(checkbox, $.closest('.product_color'), box.sel);
                  return {
                    face_idx,
                    product_color_id: pc.id,
                  };
                }),
                _p.group_by((pcf) => pcf.product_color_id),
                _p.map((pcfs) => ({
                  product_color_id: pcfs[0].product_color_id,
                  face_idxs: _p.pluck(pcfs, 'face_idx'),
                })),
              );
              if (!product_color_faces.length) $.alert('선택한 면이 없습니다.');

              setDfProjectionListUpdateIsStop(true);
              const projection_id = box.sel('df/projection/detail->projection->id');
              const el_don_page = $.closest(e.currentTarget, '.don_page');
              const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
              $.attr(el_don_wrapper, {
                prev_scroll_top: $.scroll_top(el_don_wrapper),
              });
              $.don_loader_start();
              await _p.go(
                $.post('/@api/printable_files/empty', {
                  projection_id,
                  product_color_faces: JSON.stringify(product_color_faces),
                }),
                function () {
                  return render_products_tab(e.currentTarget);
                },
                _p.noop,
                $.don_loader_end,
              );
              setDfProjectionListUpdateIsStop(false);
            }),

            $.on2('change', '.print_file_manager .upload_all input[type="file"]', async function (e) {
              const product_color_faces = _p.go(
                e.delegateTarget,
                $.find(`.printable_files input[type="checkbox"]:checked`),
                _p.map((checkbox) => {
                  const face_idx = _go(checkbox, $.closest('.product_face'), $.attr('idx'), parseInt);
                  const pc = _go(checkbox, $.closest('.product_color'), box.sel);
                  return {
                    face_idx,
                    product_color_id: pc.id,
                  };
                }),
                _p.group_by((pcf) => pcf.product_color_id),
                _p.map((pcfs) => ({
                  product_color_id: pcfs[0].product_color_id,
                  face_idxs: _p.pluck(pcfs, 'face_idx'),
                })),
              );
              setDfProjectionListUpdateIsStop(true);
              const projection_id = box.sel('df/projection/detail->projection->id');
              const el_don_page = $.closest(e.currentTarget, '.don_page');
              const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
              $.attr(el_don_wrapper, {
                prev_scroll_top: $.scroll_top(el_don_wrapper),
              });
              $.don_loader_start();
              await _p.go(
                $.upload2(e.currentTarget, {
                  url: '/@api/printable_files/uploads',
                  data: {
                    projection_id,
                    product_color_faces: JSON.stringify(product_color_faces),
                  },
                }),
                function () {
                  return render_products_tab(e.currentTarget);
                },
                _p.noop,
                $.don_loader_end,
              );
              setDfProjectionListUpdateIsStop(false);
              if (!product_color_faces.length) $.alert('선택한 면이 없습니다.');
            }),
            $.on2('click', '.make_all_auto_print_files', async (e) => {
              const all_printable_files = go(
                e.delegateTarget,
                $.find(`.printable_files input[type="checkbox"]:checked`),
              );
              if (!all_printable_files.length) return $.alert('선택된 면이 없습니다.');
              $.don_loader_start();
              try {
                const press_type_id = parseInt(e.currentTarget.dataset.press_type_id);
                setDfProjectionListUpdateIsStop(true);
                const ready_print_files = await go(
                  all_printable_files,
                  map(async (checkbox) => {
                    const product_face = go(checkbox, $.closest('.product_face'), box.sel);
                    const pc = go(checkbox, $.closest('.product_color'), box.sel);
                    const up_c = go(checkbox, $.closest('.up_c_item'), box.sel);
                    const color_code = go(up_c, (up_c) => up_c._.base_product_color.color_code);
                    return {
                      product_face,
                      printable_product_id: pc.id,
                      up_id: up_c.up_id,
                      color_code,
                    };
                  }),
                  uniqBy(sel('up_id')),
                );
                $.don_loader_end();
                const is_with_copy = press_type_id === DfAutoImageWorkConstantS.DTP.press_type_id;
                const pc_printable_files_for_insert = await DfAutoImageWorkF.eventAutoPrintFile({
                  press_type_id,
                  ready_print_files,
                });
                if (!pc_printable_files_for_insert.length)
                  throw new Error('자동화에 실패된 면 이미지들이 있습니다. 확인해주세요.');
                const results = await go(
                  pc_printable_files_for_insert,
                  map(async ({ printable_product_id, printable_file, bpf_id }) => {
                    return $.post('/@api/image_work/add_print_file', {
                      printable_product_id,
                      printable_file,
                      bpf_id,
                      is_with_copy,
                    });
                  }),
                  filter(sel('ok')),
                );
                if (!results?.length) throw new Error('실패한 면이 존재 합니다.');
                $.don_loader_start();
                const projection_id = box.sel('df/projection/detail->projection->id');
                const el_don_page = $.closest(e.currentTarget, '.don_page');
                const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
                $.attr(el_don_wrapper, {
                  prev_scroll_top: $.scroll_top(el_don_wrapper),
                });

                await $.post('/@api/image_work/completed_all_print_files', { projection_id });
              } catch (e) {
                console.log(e);
                $.alert(e.response?.data?.message || e.response?.data || e.message);
              } finally {
                await render_products_tab(e.currentTarget);
                setDfProjectionListUpdateIsStop(false);
                $.don_loader_end();
              }
            }),

            $.on2('change', '.select_press_type select', async function (e) {
              const el_file = $.closest(e.currentTarget, '.file');
              const file = box.sel(el_file);
              file.press_type_id = $.val(e.currentTarget);
              file.press_type_name = box.sel('press_types->(#' + file.press_type_id + ')->name');

              if (e.no_api) return;

              // 나머지들에 적용
              _p.go(
                $.find(e.delegateTarget, `.file[_id="${file.id}"]`),
                _p.map((el_file2) => $.find1(el_file2, 'select.don_select')),
                _p.reject((select) => $.val(select) == file.press_type_id),
                _p.each((select) =>
                  $.trigger($.val(select, file.press_type_id), 'change', {
                    no_api: true,
                  }),
                ),
              );

              // api
              const up = box.sel($.closest(e.currentTarget, '.up_item'));
              const projection_id = up.projection_id;
              const file_id = file.id;
              const { press_type_id, press_type_name } = file;
              await $.post('/@api/user_product/printable_product/update_press_types', {
                file_id,
                press_type_id,
                press_type_name,
                projection_id,
              });
            }),

            $.on2('click', '.fill_all_printable', async function (e) {
              await _p.go(
                e.delegateTarget,
                $.find('.up_item'),
                _p.map(box.sel),
                _p.each(async (up) => fill_printable_product(up)),
              );
              await e.delegateTarget.tab_opt.showed(e.delegateTarget);
            }),

            $.on2('click', 'button.edit_printable_product', async function (e) {
              const el_up_item = _p.go(e.currentTarget, $.closest('.up_item'));
              const up = box.sel(el_up_item);
              const up_c = _p.go(el_up_item, $.find1('.up_c_item'), box.sel);
              const up_c_s = _p.find(up_c._.up_c_ss, 'quantity');
              const user_made_product_color = up._.up_cs.find(
                (up_c) => up_c.product_color_id == up.product_color_id,
              )._.product_color;

              if (up_c_s && !up_c_s._.base_product_size.is_public) {
                return $.alert('삭제되었거나 품절인 사이즈입니다.');
              }
              const { is_auto } = await $.get('/@api/work_classification/is_image_work_auto', {
                base_product_id: up._.base_product.id,
              });
              if (
                ![5770, 5768, 5894, 5769, 5892, 5766, 5767, 5943, 5893, 5944, 5942].includes(
                  up._.base_product.id,
                ) &&
                is_auto
              )
                return $.alert(
                  '시안 수정 기능을 이용할수 없는 상품입니다.<br>마플에 직원 계정으로 로그인 후, 인쇄용 시안 수정 라인에 있는 [상품 보기] 버튼을 눌러 마플에서 고객 시안을 확인 해주세요.',
                );

              let base_product_size_id = _p.v(up_c_s, 'base_product_size_id');
              const el_don_page = $.closest(e.currentTarget, '.don_page');
              const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
              const prev_scroll_top = $.scroll_top(el_don_wrapper);

              const collabo_type_is_creator = $is(
                '[collabo_type="creator"]',
                $closest('.don_frame', e.currentTarget),
              );

              return _p.go(fill_printable_product(up, collabo_type_is_creator), function (up) {
                const product_color_id = sel(
                  'printable_product_id',
                  find((up_c2) => up_c.id === up_c2.id, up._.up_cs),
                );
                return _p.go(
                  $.get('/@api/product_color', { id: product_color_id }),
                  /*DF*/
                  function (printable_product) {
                    if (
                      _p.find(
                        up_c._.up_c_ss,
                        (up_c_s) => up_c_s.base_product_size_id === printable_product.base_product_size_id,
                      )
                    ) {
                      base_product_size_id = printable_product.base_product_size_id;
                    }
                    return new Promise((resolve) => {
                      $.frame.open(
                        {
                          frame_name: 'maker.printable_product',
                          closing: async function (X, printable_product) {
                            printable_product &&
                              setPfColllaboTypeBpsId(
                                printable_product,
                                box.sel('maker->collabo_type'),
                                box.sel('maker->base_product_size_id'),
                              );
                            unsetPfCollaboTypeBpsId();
                            unsetFcanvass();
                            printable_product &&
                              (await $.post('/@api/user_product/update_printable_product', {
                                up_id: up.id,
                                projection_id: up.projection_id,
                                printable_product: _p.omit(printable_product, ['_']),
                              }));
                            $.attr(el_don_wrapper, { prev_scroll_top });
                            resolve();
                          },
                        },
                        {
                          tabs: [
                            {
                              data_func: _p.c([
                                _p.extend(printable_product, {
                                  base_product_size_id,
                                }),
                                user_made_product_color,
                              ]),
                            },
                          ],
                        },
                      );
                    });
                  },
                );
              });
            }),

            $.on2('click', 'button.off_printable_product_shared', async function (e) {
              if (
                !(await $.confirm(
                  '<b>시안 연동 중단</b><br>' +
                    '1. 이 주문의 인쇄용 시안이 바로 삭제됩니다.<br>' +
                    '2. 인쇄용 시안 다시 생성 후<br>' +
                    '3. 인쇄용 파일 업로드<br><br>' +
                    '<b>주의</b><br>' +
                    "다시 시안 연동을 원하실 경우<br>'시안 연동 시작'을 누르신 후<br>'인쇄용 시안 수정 > 저장'을 다시 해주어야 합니다.<br><br>시안 연동을 중단하시겠습니까?",
                ))
              )
                return;

              const res = await $.post('/@api/reset_printable_product_shared', {
                id: $attr('data-id', e.currentTarget),
                value: false,
              });

              if (!res) $.alert('시스템 오류입니다. 개발팀에 문의해 주세요.');

              go(
                e.currentTarget,
                $closest('.up_item'),
                $setAttr(['is_printable_product_shared', false]),
                $find('.remove_printable_product'),
                (el) => $trigger('click', el),
              );
            }),

            $.on2('click', 'button.on_printable_product_shared', async function (e) {
              if (
                !(await $.confirm(
                  '시안 연동을 시작하면 동일한 상품을 가진 모든 주문서에 연동됩니다. 계속하시겠습니까?',
                ))
              )
                return;

              const res = await $.post('/@api/reset_printable_product_shared', {
                id: $attr('data-id', e.currentTarget),
                value: true,
              });

              if (!res) $.alert('시스템 오류입니다. 개발팀에 문의해 주세요.');

              go(e.currentTarget, $closest('.up_item'), $setAttr(['is_printable_product_shared', true]));
            }),

            $.on2('click', 'button.remove_printable_product', function (e) {
              $.don_loader_start();
              return _p.go(
                e.currentTarget,
                $.closest('.up_item'),
                _p.tap(
                  box.sel,
                  _p.pick(['id', 'projection_id']),
                  _p($.post, '/@api/user_product/remove_printable_product'),
                ),
                render_products_tab,
                _p.noop,
                $.don_loader_end,
              );
            }),

            ...map(
              (type) =>
                pipe(
                  $.on(
                    'click',
                    `.up_item button.add_up_memo_${type}`,
                    common_memo_pipe(type)(($item, $memo) => {
                      $.attr($item, `has_memo_${type}`, true);
                      $.attr($memo, 'mode', 'edit');
                      $.find1($memo, 'textarea').focus();
                    }),
                  ),

                  $.on(
                    'click',
                    `.cell.up_memo_${type} .buttons button.close`,
                    common_memo_pipe(type)(($item, $memo, b_up) => {
                      $.attr($item, `has_memo_${type}`, !!sel(['memo', type], b_up));
                      $.attr($memo, 'mode', 'read');
                    }),
                  ),

                  $.on2(
                    'click',
                    `.cell.up_memo_${type} .buttons button.save`,
                    common_memo_pipe(type)(($item, $memo, b_up) => {
                      const textarea = $.find1($memo, '.edit_mode .memo_value');

                      if (!textarea.value) return $.alert('내용을 입력해 주세요.');

                      $.don_loader_start();
                      return _p.go(
                        $.post('/@api/projection/up/update', {
                          id: b_up.id,
                          type,
                          body: textarea.value || '',
                        }),
                        async (up) => {
                          b_up.memo = up.memo || { worker: '', seller: '' };
                          b_up.memo = typeof b_up.memo == 'string' ? JSON.parse(b_up.memo) : b_up.memo;
                          $.html(
                            $.find1($memo, '.read_mode .text'),
                            UtilS.linkify(sel(['memo', type], b_up)) || '',
                          );
                          $.attr($item, `has_memo_${type}`, !!sel(['memo', type], b_up));
                          $.attr($memo, 'mode', 'read');
                          $.don_loader_end();
                          if (type == 'seller_all') await render_products_tab($item);
                        },
                      );
                    }),
                  ),

                  $.on2(
                    'click',
                    `.cell.up_memo_${type} .buttons button.delete`,
                    common_memo_pipe(type)(($item, $memo, b_up) => {
                      const textarea = $.find1($memo, '.edit_mode .memo_value');

                      $.don_loader_start();
                      return _p.go(
                        $.post('/@api/projection/up/update', {
                          id: b_up.id,
                          type,
                          memo: null,
                        }),
                        async (up) => {
                          b_up.memo = up.memo;
                          b_up.memo = typeof b_up.memo == 'string' ? JSON.parse(b_up.memo) : b_up.memo;
                          $.text(
                            $.find1($memo, '.read_mode .text'),
                            UtilS.linkify(sel(['memo', type], b_up)),
                          );
                          $.val(textarea, '');
                          $.attr($item, `has_memo_${type}`, !!sel(['memo', type], b_up));
                          $.attr($memo, 'mode', 'edit');
                          $.don_loader_end();
                          if (type == 'seller_all') await render_products_tab($item);
                        },
                      );
                    }),
                  ),

                  $.on(
                    'click',
                    `.cell.up_memo_${type} .buttons button.edit`,
                    common_memo_pipe(type)((_, $memo) => {
                      $.attr($memo, 'mode', 'edit');
                      const textarea = $.find1($memo, 'textarea');
                      const val = $.val(textarea);
                      $.val(textarea, '');
                      textarea.focus();
                      $.val(textarea, val);
                    }),
                  ),
                ),
              ['worker', 'seller', 'seller_all'],
              /* 'worker', 'seller', 'seller_all' 변경시 정익님한테도 알려주세요~! */
            ),

            $.on2('click', '.add_up', function (e) {
              $.don_loader_start();
              const el_up_item = $.closest(e.currentTarget, '.up_item');
              const up = box.sel(el_up_item);
              const up_c = _p.go(el_up_item, $.find1('.up_c_item'), box.sel);
              const product_color = up_c.printable_product_id
                ? up_c._.printable_product
                : up_c._.product_color;
              const product_color_id = product_color.id;
              $.add_class($1('html'), 'can_product_change');
              let new_up;
              return _p.go($.get('/@api/product_color', { id: product_color_id }), function (product_color) {
                /*DF*/
                return new Promise((resolve) => {
                  $.don_loader_end();
                  $.frame.open(
                    {
                      frame_name: 'maker.printable_product',
                      closing: async function (X, printable_product) {
                        printable_product &&
                          setPfColllaboTypeBpsId(
                            printable_product,
                            box.sel('maker->collabo_type'),
                            box.sel('maker->base_product_size_id'),
                          );
                        unsetPfCollaboTypeBpsId();
                        $.remove_class($1('html'), 'can_product_change');
                        printable_product &&
                          !printable_product.id &&
                          (new_up = await $.post('/@api/user_product/add_to_projection', {
                            projection_id: up.projection_id,
                            printable_product: _p.omit(printable_product, ['_']),
                          }));
                      },
                      closed: function () {
                        if (!new_up) return resolve();
                        unsetFcanvass();
                        _p.defer(function () {
                          const up_list = $.closest(el_up_item, '.up_list');
                          box.sel(up_list).push(new_up);
                          _p.go(
                            _p.extend(new_up, { _temp: true }),
                            tmpl_up_item,
                            $.el,
                            $.prepend_to(up_list),
                            G.mp.maker.draw_product_face_in_ups,
                          );

                          const el_don_page = $.closest(el_up_item, '.don_page');
                          const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
                          $.attr(el_don_wrapper, { prev_scroll_top: 0 });

                          resolve(open_edit_sizes(new_up, new_up._.up_cs[0]));
                        });
                      },
                    },
                    {
                      tabs: [{ data_func: _p.c([product_color]) }],
                    },
                  );
                });
              });
            }),

            $.on2('click', '.add_color', async function (e) {
              const up = box.sel($.closest(e.currentTarget, '.up_item'));
              const up_c =
                _p.find(up._.up_cs, (up_c) => _p.find(up_c._.up_c_ss, 'quantity')) ||
                _p.find(up._.up_cs, (up_c) => up.id != up_c) ||
                up;
              const has_quantity_up_cs = filter(
                (up_c) => sumBy((up_c_s) => up_c_s.quantity, up_c._.up_c_ss),
                up._.up_cs,
              );
              const available_colors = up.store_id
                ? await $.get('/@api/user_product/available_colors', { product_id: up_c.product_color_id })
                : pluck('id', up._.base_product._.base_product_colors);

              const data_func = _p.c({
                title: `상품 번호 #${up.id} - ${up._.base_product.name}`,
                bpcs: go(
                  up._.base_product._.base_product_colors,
                  filter(({ id }) => available_colors.includes(id)),
                  reject((bpc) => some((up_c) => up_c.base_product_color_id == bpc.id, has_quantity_up_cs)),
                ),
              });
              return new Promise(function (resolve) {
                $.frame.open(
                  {
                    frame_name: 'df.projection.detail.add_color',
                    up,
                    up_c,
                    closing: async function (X, need_render) {
                      resolve(need_render && (await render_products_tab(e.currentTarget)));
                    },
                  },
                  { tabs: [{ data_func }] },
                );
              });
            }),

            $.on2('click', '.edit_sizes', function (e) {
              const up_c = box.sel(e.currentTarget);
              const up = box.sel($.closest(e.currentTarget, '.up_item'));
              return open_edit_sizes(up, up_c, e.currentTarget);
            }),

            G.df.projection.detail.up_item_init,
          );
        },
        appended: _pipe(G.mp.maker.draw_product_face_in_ups, init_select_press_type),
      },
      {
        tab_name: 'projection.detail.tasks',
        title: '태스크',
        template: () => '',
        hiding: G.df.projection.detail.tab_hiding,
        hided: G.df.projection.detail.tab_hided,
        showed: G.df.projection.detail.base_tab_showed(null, (don_tab_el) => {
          return G.df.task.projection.render_projection_detail_tasks_tab(don_tab_el);
        }),
        appended: __(G.df.task.projection.projection_detail_tasks_event_init),
      },
      ...(box.sel('is_user->_->policies->mp_worker_policy')
        ? [
            {
              tab_name: 'projection_detail_info',
              title: '배송 / 재고모드 / 주문상품 / 문자',
              template: () => '',
              hiding: G.df.projection.detail.tab_hiding,
              hided: G.df.projection.detail.tab_hided,
              showed: G.df.projection.detail.base_tab_showed(
                (p, _en = rowTo_en(p)) => pug`
          .detail_price[lang="${p.lang}"]
            .top_id
              a[href="/projection/detail/${p.id}" target="_blank"] ${p.lang == 'kr' ? '[국문]' : '[영문]'} #${
                p.id
              }
            h2.no_merged_parent 주문자 / 받는이
            .df_grid.prj_item.no_merged_parent[prj_id="${p.id}" _sel="df/projection/detail->projection"]
              ${
                p.special_request
                  ? `
              <div class="cell special_request">
                <div class="body">고객 요청 사항 :<br>${UtilS.escape(p.special_request || '').replace(
                  /\n/g,
                  '<br>',
                )}</div>
              </div>
              `
                  : ''
              }
              .orderer
                table
                  tr
                    th.name 주문자
                    td.name
                      input[type="text" value="${UtilS.escape(p.orderer_name || '')}"]
                    th.mobile 주문자 전번
                    td.mobile
                      input[type="text" value="${UtilS.escape(p.orderer_mobile || '')}"]
                    th.email 주문자 이메일
                    td.email
                      input[type="text" value="${UtilS.escape(p.orderer_email || '')}"]
                    td.save
                      button[type="button"].orderer_save 변경 저장
              .grid.shipping_list
                ${makeDfProjectionShipItemsHtml({ prj: p })}
            h2 재고 모드
            .bp_stocks[_sel="df/projection/detail->projection->_->bp_stocks"] ${makeDfProjectionListBpStocksHtml(
              p._.bp_stocks,
            )}
            <br>
            h2 주문 상품
            ${function () {
              if (!box.sel('user->_->policies->shipping_data_policy')) return '';
              return pug`
                .order_down_print.no_merged_parent[style="margin: 0 0 10px;"]
                  form[method="POST" action="/@api/mp/projection/excel_download" style="display: inline-block; margin-right: 10px;"]
                    input[type="hidden" name="user_id" value="${_p.v(p, 'user_id')}"]
                    input[type="hidden" value="${_p.v(p, 'id')}" name="projection_id"]
                    button[type="submit"] 주문정보 엑셀다운
                  button[type="button"].btn_projection_receipt 거래명세서 인쇄
              `;
            }}
            .df_grid
              table
                tr
                  th 총 수량
                  th 자동 할인 전 가격
                  th 자동 할인된 주문 가격
                  th 배송비
                  th 포인트 할인
                  th 쿠폰 할인
                  th 추가/할인 금액
                  th 추가배송 금액
                  th 최종 가격
                tr
                  td ${_p.commify(p.quantity)}
                  td.mp_currency ${PriceS.pricify_by(p['product_original_price' + _en], _en)}
                  td.mp_currency ${PriceS.pricify_by(p['product_price' + _en], _en)}
                  td.mp_currency ${PriceS.pricify_by(p['shipping_price' + _en], _en)}
                  td.mp_currency ${PriceS.pricify_by(p['point_price' + _en], _en)}
                  td.mp_currency ${PriceS.pricify_by(p['coupon_price' + _en], _en)}
                  td.mp_currency ${PriceS.pricify_by(p['other_price' + _en], _en)}
                  td.mp_currency ${PriceS.pricify_by(p['other_shipping_price' + _en], _en)}
                  td.mp_currency ${PriceS.pricify_by(p['total_price' + _en], _en)}
            .df_grid.up_list[_sel="df/projection/detail->projection->_->ups"]
              ${_p.sum(p, _p.filter(p._.ups, up_total_quantity), tmpl_up_item_price)}
            <br>
            h2.no_merged_parent 문자 전송 및 내역
            .projection_sms_area
              .send_editor
                ${makeSmsSendFormDFHtml({
                  title: '문자 전송',
                  countries: box.sel('countries'),
                  receive_number: p.orderer_mobile,
                  collabo_type: p.collabo_type,
                  marketplace_name: p.marketplace_name,
                  projection: p,
                })}
              .send_list
                h3 문자 내역 <span>(최대 30개까지 보입니다)</span>
                ul
                  ${strMap(makeSmsProjectionItemHtml, sel('_.sms_logs', p))}
          `,
                G.mp.maker.draw_product_face5,
              ),
              appended: _p.pipe(
                tap(msgSelectBoxEvent),
                $.on2('click', '.orderer_save', async function (e) {
                  const el_orderer = $.closest(e.currentTarget, '.orderer');
                  await $.post('/@api/projection/update', {
                    orderer_name: $.val($.find1(el_orderer, 'td.name input')),
                    orderer_mobile: $.val($.find1(el_orderer, 'td.mobile input')).replace(/[^0-9]/g, ''),
                    orderer_email: $.val($.find1(el_orderer, 'td.email input')),
                    id: box.sel('df/projection/detail->projection->id'),
                  });
                  const don_tab = $.closest(e.currentTarget, '.don_tab');
                  await don_tab.tab_opt.showed(don_tab);
                }),
                $.on('keyup', '.send_editor textarea', function (e) {
                  const val = $.val(e.$currentTarget);
                  const byte_txt_el = $.find1($.closest(e.$currentTarget, '.control'), 'p .byte_txt');
                  const txt1_el = $.find1($.closest(e.$currentTarget, '.control'), 'p .txt1');

                  const abc = (val.length * 9 - encodeURIComponent(val).length) / 8; // 영문숫자의 개수
                  const msg_byte = parseInt((val.length - abc) * 2 + abc, 10); // euc-kr 바이트 구하기

                  $.html(byte_txt_el, msg_byte);

                  if (msg_byte > 90) $.show(txt1_el);
                  else $.hide(txt1_el);
                }),
                $.on('change', '.send_editor input[name="sms_type"]', function (e) {
                  const cont = $.find1(e.delegateTarget, '.ks_send_area');
                  $.attr(cont, 'sms_type', $.val(e.currentTarget));
                }),
                $.on('keydown', '.send_editor input[name="receive_num"]', function (e) {
                  e.keyCode == 13 &&
                    $.trigger($.find1($.closest(e.currentTarget, '.receive_area'), '.add_number'), 'click');
                }),
                $.on('click', '.send_editor .add_number', function (e) {
                  const el_receive = $.closest(e.currentTarget, '.receive_area');
                  const ul = $.find1(el_receive, '.receive_list');
                  const dialing_code = $.val($.find1(el_receive, '.dialing_code'));
                  const input_receive_num = $.find1(el_receive, 'input[name="receive_num"]');
                  let number = $.val(input_receive_num);
                  number = number.replace(/[^0-9]/g, '');
                  number = checkPhoneNumberSms(number) ? number.substring(1) : number;

                  if (_p.is_empty(number)) return $.alert('번호를 입력해 주세요.');

                  $.append(ul, makeSmsSendReceiveItemDFHtml(`${dialing_code}-${number}`));
                  $.val(input_receive_num, '');
                  input_receive_num.focus();
                }),
                $.on('click', '.send_editor .receive_list .btn_del', function (e) {
                  $.remove($.closest(e.currentTarget, 'li'));
                }),
                $.on('click', '.btn_add_img', function (e) {
                  $trigger('click', $find('input[name="sms_img_upload"]', e.delegateTarget));
                }),
                $.on('change', 'input[name="sms_img_upload"]', eventSmsImageUpload),
                $.on2('click', '.projection_sms_area .btn_send', function (e) {
                  const send_editor = $.closest(e.currentTarget, '.send_editor');
                  const sms_type = $.attr($.find1(send_editor, '.ks_send_area'), 'sms_type');
                  const send_list = $.find1($.closest(e.currentTarget, '.projection_sms_area'), '.send_list');
                  const receive_list = $.find(send_editor, 'ul.receive_list li');
                  const textarea = $.find1(send_editor, 'textarea');
                  const msg = $.val(textarea);

                  let caller_number1 = '';
                  let caller_number2 = '';
                  let caller_number3 = '';
                  let collabo_type = '';
                  let etc_sms_type = '';

                  if (sms_type == '') {
                    collabo_type = '';
                    caller_number1 = '1566';
                    caller_number2 = '9437';
                  } else if (sms_type == 'creator') {
                    collabo_type = 'creator';
                    caller_number1 = '1566';
                    caller_number2 = '5496';
                  } else if (sms_type == NESS_SMS_TYPE.NESS) {
                    collabo_type = 'creator';
                    etc_sms_type = NESS_SMS_TYPE.NESS;
                    caller_number1 = '1566';
                    caller_number2 = '7960';
                  } else if (sms_type == NESS_SMS_TYPE.NESS_PRIME) {
                    collabo_type = 'creator';
                    etc_sms_type = NESS_SMS_TYPE.NESS_PRIME;
                    caller_number1 = '1566';
                    caller_number2 = '2180';
                  } else if (sms_type == MPS_SMS_TYPE.SHOPFREECA) {
                    collabo_type = 'creator';
                    caller_number1 = '070';
                    caller_number2 = '7815';
                    caller_number3 = '0009';
                  } else if (sms_type == 'direct') {
                    collabo_type = '';
                    caller_number1 = $.val($.find1(send_editor, 'input.caller_number1'));
                    caller_number2 = $.val($.find1(send_editor, 'input.caller_number2'));
                    caller_number3 = $.val($.find1(send_editor, 'input.caller_number3'));
                  } else {
                    collabo_type = '';
                  }

                  if (!receive_list.length) return $.alert('번호를 추가 후 이용해 주세요.');

                  if (_p.is_empty(msg)) {
                    $.alert('보낼 메시지를 입력하세요.');
                    return;
                  }

                  return _p.go(
                    $.post('/@api/sms2/send', {
                      projection_id: box.sel('df/projection/detail->projection->id'),
                      caller_number1: caller_number1,
                      caller_number2: caller_number2,
                      caller_number3: caller_number3,
                      etc_sms_type,
                      receive_numbers: _p.map(receive_list, (li) => {
                        return $.text($.find1(li, '.num'));
                      }),
                      msg: msg,
                      collabo_type: collabo_type,
                    }),
                    function (data) {
                      if (!data || !data.result) return $.alert('전송 실패 다시 시도해 주세요.');

                      return _p.go(
                        void 0,
                        function () {
                          return $.alert('전송되었습니다.');
                        },
                        function () {
                          if (data) $.prepend($.find1(send_list, 'ul'), makeSmsProjectionItemHtml(data));
                          $.val(textarea, '');
                          $.text($.find(send_editor, '.byte .byte_txt'), 0);
                          $.hide($.find(send_editor, '.byte .txt1'));
                        },
                      );
                    },
                  );
                }),
                $.on('click', '.product_color .product_face .thumb', function (e) {
                  const el_pc = _go(e.currentTarget, $.closest('.product_color'));
                  const face_idx = _go(e.currentTarget, $.closest('.product_face'), $.attr('idx'), parseInt);

                  G.df.projection.pc_preview.frame(
                    box.sel(el_pc),
                    _go(el_pc, $.closest('.up_item'), box.sel),
                    $.is(el_pc, '.printable'),
                    _go(
                      e.currentTarget,
                      $.closest('.up_c_item'),
                      $.find1('.up_c_s_item.selected .size'),
                      $.text,
                    ),
                    face_idx,
                  );
                }),
                $.on('click', '.btn_projection_receipt', function () {
                  window.open(
                    '/df/print_receipt/' + box.sel('df/projection/detail->projection->id'),
                    'receipt_popup',
                    'width=1100, height=700, top=100, left=200, menubar=1, toolbar=1',
                  );
                }),
                makeDfProjectionShipEvent,
              ),
            },
            G.df.projection.detail_payment.tab,
          ]
        : []),
      G.df.projection.detail_user_projections.tab,
      baseProjectionDetailSubProjectionsTab({
        title: '마플샵 병합',
        merged_type: 'parent',
        tab_name: 'projection_detail_creator_parent_tab',
        order_by: 'DESC',
      }),
      baseProjectionDetailSubProjectionsTab({
        title: '마플샵 샘플 (제작)',
        merged_type: 'sample',
        tab_name: 'projection_detail_creator_sample_tab',
        order_by: 'DESC',
      }),
      baseProjectionDetailSubProjectionsTab({
        title: '빠른POD (제작)',
        merged_type: 'fast_child',
        tab_name: 'projection_detail_creator_fast_child_tab',
        order_by: 'DESC',
      }),
      baseProjectionDetailSubProjectionsTab({
        title: '마플샵 주문 (배송)',
        merged_type: 'child',
        tab_name: 'projection_detail_creator_child_tab',
        order_by: 'ASC',
      }),
    ],
  });

  const tmpl_up_item = async (up, p) => {
    try {
      up.memo = typeof up.memo == 'string' ? JSON.parse(up.memo) : up.memo;
    } catch (e) {
      up.memo = { worker: up.memo.replace('{"worker": "', '').replace('"}', '') };
    }
    return pug`
      .prj.grid.up_item[_id="${up.id}" _sel="./(#${up.id})" merged_type="${
        p.merged_type
      }" has_memo_worker="${!!sel('memo.worker', up)}" has_memo_seller="${!!sel(
        'memo.seller',
        up,
      )}" has_memo_seller_all="${!!sel('memo.seller_all', up)}" quantity="${
        up._temp || up_total_quantity(up)
      }" is_printable_product_shared="${sel(
        '_.product_color._.stores_product._.origin_stores_product.is_printable_product_shared',
        up,
      )}"]
        .options.cell
          button[type="button"].go_to_marpple_product
            a[target="_blank" href="https://www.marpple.com/kr/product/detail?bp_id=1&pc_id=${
              up.product_color_id
            }"] 상품 보기
          button[type="button"].add_up_memo_worker 메모 생성하기
          ${
            up.store_id && ['sample', 'parent'].includes(p.merged_type)
              ? pug`
                button[type="button"].add_up_memo_seller 셀러상품요청 생성
                button[type="button"].add_up_memo_seller_all 셀러공통요청 생성
              `
              : ''
          }
          ${
            up.store_id &&
            ![STORE_PRODUCT_CUSTOM_LEVEL.USER, STORE_PRODUCT_CUSTOM_LEVEL.ASSET].includes(
              up._.product_color?._?.stores_product?._?.origin_stores_product?.custom_level,
            )
              ? pug`
                button[type="button" data-id="${sel(
                  '_.product_color._.stores_product._.origin_stores_product.id',
                  up,
                )}"].on_printable_product_shared 시안 연동 시작하기
                button[type="button" data-id="${sel(
                  '_.product_color._.stores_product._.origin_stores_product.id',
                  up,
                )}"].off_printable_product_shared 시안 연동 중단하기
              `
              : ''
          }
          button[type="button"].remove_printable_product 인쇄용 시안 삭제
          button[type="button"].edit_printable_product 인쇄용 시안 수정
        ${strMap(
          ({ type, name }) => pug`
            .cell.up_memo[class="up_memo_${type}" mode="${sel(['memo', type], up) ? 'read' : 'edit'}"]
              .edit_mode
                textarea.body.memo_value[rows="3" placeholder="${name}"] ${(sel(['memo', type], up) || '')
                  .replace(/<br>/g, '\n')
                  .replace(/&quot;/g, '"')}
                .buttons
                  button[type="button"].close 취소
                  button[type="button"].save ${name} 저장
              .read_mode
                .body.text <b>${name}</b>: ${(() => {
                  if (!sel(['memo', type], up)?.length) return '';
                  return UtilS.linkify(sel(['memo', type], up).replace(/<br>/g, '\n'));
                })()}
                .buttons
                  button[type="button"].delete 삭제
                  button[type="button"].edit ${name} 수정
          `,
          [
            { type: 'worker', name: '메모' },
            { type: 'seller', name: '셀러상품요청' },
            { type: 'seller_all', name: '셀러공통요청' },
          ],
        )}
        .cell.head_between
          .title ${makeUpTitle(up)}
            ${
              up.store_id
                ? legacyHtml` <button type="button" class="add_color"></button> `
                : legacyHtml`
                    <button type="button" class="add_color"></button>
                    <button type="button" class="add_up">이 디자인으로 다른 상품 추가</button>
                  `
            }
            ${
              up._.base_product.tmpl_file_url
                ? legacyHtml`
                    <a
                      class="tmpl_file_url button"
                      style="margin-left: 16px;"
                      href="${up._.base_product.tmpl_file_url}"
                      target="_blank"
                      >템플릿 파일 다운로드</a
                    >
                  `
                : ''
            }
          .id 상품 번호 #${up.id}
        ${makeDfProjectionListUpCItemsHtml(up)}
    `;
  };

  const tmpl_up_item_price = (p, up) => {
    const _en = rowTo_en(p);
    return pug`
      .up_item_price[_id="${up.id}" _sel="./(#${up.id})"]
        .header
          .name 상품 번호 #${up.id} - ${up._.base_product.name}
        table[_sel="./_->up_cs"]
          tr
            th.up_c 상품
            th.color 색상
            th.size 사이즈
            th.quantity 수량
            th.bp_price 기본 가격
            th.bps_price 사이즈추가금
            th.print_price 인쇄+옵션
            th.price 가격
            th.discounted_price 할인 후 최종가격
            th.total_price 합계
          ${
            up.base_product_id == null
              ? pug`
            tr
              td[colspan="9"] 삭제된 상품
          `
              : _go(
                  up._.up_cs,
                  _filter(up_c_total_quantity),
                  _sum((up_c, up_c_ss = _p.filter(up_c._.up_c_ss, 'quantity')) =>
                    _p.sum(
                      up_c_ss,
                      (up_c_s, i) => pug`
              tr
                ${
                  i == 0
                    ? pug`
                td.up_c[rowspan="${up_c_ss.length}" _sel="./(#${up_c.id})"]
                  div.product_color.${up_c._.printable_product.id ? 'printable' : ''}[_sel="./_->${
                    up_c._.printable_product.id ? 'printable_product' : 'product_color'
                  }"] ${_p.map(
                    up_c._.printable_product.id
                      ? up_c._.printable_product.product_faces2.value
                      : up_c._.product_color.product_faces2.value,
                    (pf, i) => legacyHtml`
                          <div class="product_face" _sel="./product_faces2->value->${i}" idx=${i}>
                            <div class="thumb">
                              <div class="img canvas_120">
                                <canvas></canvas>
                                <div class="don_loader_wrap2">
                                  <div class="don_loader_img2"></div>
                                </div>
                              </div>
                              <div class="name">${pf.face_name}</div>
                            </div>
                          </div>
                        `,
                  )}`
                    : ''
                }
                td.color ${up_c_s._.base_product_color.name2 || up_c_s._.base_product_color.name}
                td.size ${
                  up_c_s._.base_product_size.name == up_c_s._.base_product_size.short_name
                    ? up_c_s._.base_product_size.name
                    : `${up_c_s._.base_product_size.name} (${up_c_s._.base_product_size.short_name})`
                }
                td.quantity ${_p.commify(up_c_s.quantity)}개
                td.bp_price.mp_currency ${PriceS.pricify_by(up_c_s['bp_price' + _en], _en)}
                td.bp_price.mp_currency ${PriceS.pricify_by(up_c_s._.base_product_size['price' + _en], _en)}
                td.print_price.mp_currency ${PriceS.pricify_by(up_c_s['print_price' + _en], _en)}
                td.price.mp_currency ${PriceS.pricify_by(up_c_s['price' + _en], _en)}
                td.discounted_price.mp_currency ${PriceS.pricify_by(up_c_s['discounted_price' + _en], _en)}
                td.total_price.mp_currency ${PriceS.pricify_by(
                  PriceS.mult(up_c_s['discounted_price' + _en], up_c_s.quantity),
                  _en,
                )}
            `,
                    ),
                  ),
                )
          }
    `;
  };

  function fill_printable_product(user_product, merge_all_creator_projection = false) {
    if (user_product.printable_product_id) return user_product;
    $.don_loader_start();
    return _p.go(
      user_product,
      _p.pick('id'),
      (body) => _p.extend(body, { merge_all_creator_projection }),
      _p($.post, '/@api/user_product/fill_printable_product'),
      _p.tap(_p.noop, $.don_loader_end),
    );
  }

  const img_thumbnail_error = function (parent) {
    return _p.go(
      parent,
      $.find('.file img'),
      _p.each((img) => {
        if (!img.getAttribute('src')) return;
        img.onerror = function (e) {
          const src = G.to_original(e.target.currentSrc);
          if (!src) return;
          e.target.src = src;
        };
      }),
    );
  };

  const dci_init = _p.pipe(
    $.on2('click', 'button.dci_case', async function (e) {
      const product_color_id = _p.go(e.currentTarget, $.closest('.product_color'), box.sel, _p.v('id'));
      const projection_id = _p.go(e.currentTarget, $.closest('.up_item'), box.sel, _p.v('projection_id'));
      try {
        $.don_loader_start();
        await downloadAutoPhonecasePrintImg(product_color_id, projection_id);
        $.don_loader_end();
      } catch (e) {
        $.alert('Error : ' + e);
        $.don_loader_end();
        throw e;
      }
    }),
    $.on2('click', '.printable_files .file, .download', async (e) => {
      const task_item = go(e.currentTarget, $closest('.task_item'), box.sel);
      const up_c = go(e.currentTarget, $closest('.up_c_item'), box.sel);
      if (box().is_user._.policies.task_progress_policy && task_item?.worker_id === box().is_user.id)
        await axios.patch('/@api/task/progress_create_by_download', {
          task_id: task_item.id,
          up_c_id: up_c.id,
        });
    }),
    $delegate('click', 'button.auto_print_img.by_customer', async (e) => {
      const task_item = go(e.currentTarget, $closest('.task_item'), box.sel);
      if (box().is_user._.policies.task_progress_policy && task_item.type !== 'printing')
        return $.alert('제작 태스크에서 이미지를 다운 받아주세요.');
      const up_c = _p.go(e.currentTarget, $.closest('.up_c_item'), box.sel);
      if (up_c._.printable_product?.id && box().is_user._.policies.task_progress_policy) {
        return $.alert('아래 인쇄용 시안의 인쇄용 파일을 다운 받아주세요.');
      }
      const custom_level = go(
        e.currentTarget,
        $closest('.up_item'),
        box.sel,
        sel('_.product_color._.stores_product._.origin_stores_product.custom_level'),
      );

      $.don_loader_start();
      try {
        const product_color = up_c._.product_color;
        const base_product = up_c._.base_product;
        const base_product_color = up_c._.base_product_color;
        const up_c_ss = up_c._.up_c_ss;
        const { merged_type, important } = await $.get('/@api/projection/for_print_file', {
          id: up_c.projection_id,
        });
        await go(
          up_c_ss,
          filter((up_c_s) => up_c_s.quantity && up_c_s.base_product_size_id),
          each(async (up_c_s) => {
            if (
              VectorEditorConstantS.KEYRING_EDITOR == base_product.maker_type ||
              VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR == base_product.maker_type
            ) {
              await go(
                SVGEditorUtilF.vectorDownload({
                  projection_id: up_c.projection_id,
                  up_id: up_c.up_id,
                  quantity: up_c_s.quantity,
                  product_id: product_color.id,
                  up_c_s_id: up_c_s.id,
                }),
                each(({ canvas_blob, file_name }) => {
                  saveAs(canvas_blob, file_name);
                }),
              );
              return;
            }
            await NewMakerPrintResultF.downloadPrintFile({
              product_faces2: product_color.product_faces2.value,
              all_faces_single_color: product_color.product_faces2.all_faces_single_color,
              base_product_size_id: up_c_s.base_product_size_id,
              base_product_color_id: up_c.base_product_color_id,
              base_product,
              title: NewMakerPrintResultF.makeFileName({
                important,
                up_c,
                base_product,
                up_c_s,
                merged_type,
                base_product_color,
                selected_option_group: product_color._.selected_option_group,
                is_nexon: custom_level === 'USER',
              }),
            });
          }),
        );
        if (box().is_user._.policies.task_progress_policy && task_item?.worker_id === box().is_user.id)
          await axios.patch('/@api/task/progress_create_by_download', {
            task_id: task_item.id,
            up_c_id: up_c.id,
          });
      } catch (e) {
        console.log(e);
        $.alert('문제가 발생했습니다.');
      } finally {
        $.don_loader_end();
      }
    }),
    $.on2('click', 'button.auto_print_img.template_meta_print_file', async (e) => {
      const task_item = go(e.currentTarget, $closest('.task_item'), box.sel);
      if (box().is_user._.policies.task_progress_policy && task_item.type !== 'printing')
        return $.alert('제작 태스크에서 이미지를 다운 받아주세요.');
      $.don_loader_start();
      const product_color = _p.go(e.currentTarget, $.closest('.product_color'), box.sel);
      const up_c = _p.go(e.currentTarget, $.closest('.up_c_item'), box.sel);
      const base_product = up_c._.base_product;
      const base_product_color = up_c._.base_product_color;
      const up_c_ss = up_c._.up_c_ss;
      const { merged_type, important } = await $.get('/@api/projection/for_print_file', {
        id: up_c.projection_id,
      });
      const custom_level = go(
        e.currentTarget,
        $closest('.up_item'),
        box.sel,
        sel('_.product_color._.stores_product._.origin_stores_product.custom_level'),
      );

      try {
        await go(
          up_c_ss,
          filter((up_c_s) => up_c_s.quantity && up_c_s.base_product_size_id),
          each(async (up_c_s) => {
            if (
              VectorEditorConstantS.KEYRING_EDITOR == base_product.maker_type ||
              VectorEditorConstantS.ACRYLIC_FIGURE_EDITOR == base_product.maker_type
            ) {
              await go(
                SVGEditorUtilF.vectorDownload({
                  projection_id: up_c.projection_id,
                  up_id: up_c.up_id,
                  quantity: up_c_s.quantity,
                  product_id: product_color.id,
                  up_c_s_id: up_c_s.id,
                }),
                each(({ canvas_blob, file_name }) => {
                  saveAs(canvas_blob, file_name);
                }),
              );
              return;
            }
            await NewMakerPrintResultF.downloadPrintFile({
              important,
              product_faces2: product_color.product_faces2.value,
              base_product_size_id: up_c_s.base_product_size_id,
              base_product_color_id: up_c.base_product_color_id,
              all_faces_single_color: product_color.product_faces2.all_faces_single_color,
              base_product,
              title: NewMakerPrintResultF.makeFileName({
                up_c,
                base_product,
                up_c_s,
                merged_type,
                base_product_color,
                selected_option_group: product_color._.selected_option_group,
                is_nexon: custom_level === 'USER',
              }),
            });
          }),
        );
        if (box().is_user._.policies.task_progress_policy && task_item?.worker_id === box().is_user.id)
          await axios.patch('/@api/task/progress_create_by_download', {
            task_id: task_item.id,
            up_c_id: up_c.id,
          });
      } catch (e) {
        console.log(e);
        $.alert('문제가 발생 했습니다.');
      } finally {
        $.don_loader_end();
      }
    }),
    $.on2('click', 'button.template_print', async function (e) {
      const up_c = _p.go(e.currentTarget, $.closest('.up_c_item'), box.sel);
      const prj =
        _p.go(e.currentTarget, $.closest('.prj_item'), box.sel) ||
        _p.go(e.currentTarget, $.closest('.prj.up_item'), box.sel);
      try {
        $.don_loader_start();
        is_load_font_important.value = true;
        await downloadFileForPDland(up_c.id, prj);
        is_load_font_important.value = false;
        $.don_loader_end();
      } catch (e) {
        $.alert('Error : ' + e);
        $.don_loader_end();
        throw e;
      }
    }),
    $.on('click', 'button.dci', async function (e) {
      $.don_loader_start();
      const base_product_id = go(
        e.currentTarget,
        $closest('.up_item'),
        (up_item) => up_item && box.sel(up_item),
        sel('base_product_id'),
      );

      const is_ring = carved_bp_ids.includes(base_product_id);
      _p.go(
        e.currentTarget,
        $.closest('.designs'),
        _p.add_arg(box.sel, makePrintableComposedImage),
        async function (el_pf, url) {
          if (url === 'cancel') return;
          if (!url) return alert('실패했습니다.');
          if (is_ring) url = await makeDataURLToBlack(url);
          const up = box.sel($.closest(el_pf, '.up_item'));
          const pf = box.sel(el_pf);
          G.mp.maker.download(url, `주문번호_${up.projection_id}_상품번호_${up.id}_${pf.face_name}`);
        },
        $.don_loader_end,
      );
    }),
    $.on('click', 'button.print_area_down', async (e) => {
      $.don_loader_start();
      const product_face_designs_el = go(e.currentTarget, $.closest('.designs'));
      const up = box.sel($closest('.up_item')(product_face_designs_el));
      const idx = $attr('idx')(product_face_designs_el);
      const product_color = box.sel($closest('.right.product_color')(product_face_designs_el));
      const product_face = product_color.product_faces2.value[idx];
      const { size_faces } = await $.get('/@api/prerequisite_maker/base_product_faces/size_faces', {
        id: product_face.bpf_id,
      });
      const {
        print: { cm, px: print_area },
        dpi,
      } = go(
        size_faces,
        find((sf) => sf.base_product_size_id === product_color.base_product_size_id),
      );
      const width = (cm.width / 2.54) * (dpi || 300);
      const canvas = await makeOnlyDesignFaceCanvasByPrintArea({
        product_face,
        print_area,
        width,
      });
      const a = document.createElement('a');
      a.href = changeDpiDataUrl(canvas.toDataURL('image/png', 1), dpi);
      a.download = `#${up.projection_id}_${up.id}_${product_face.face_name}.png`;
      a.click();
      $.don_loader_end();
    }),
    $.on('click', 'button.resized_dci', async function (e) {
      $.don_loader_start();
      const product_face_designs_el = go(e.currentTarget, $.closest('.designs'));
      const idx = $attr('idx')(product_face_designs_el);
      const up = box.sel($closest('.up_item')(product_face_designs_el));
      const product_color = box.sel($closest('.right.product_color')(product_face_designs_el));
      const pf = product_color.product_faces2.value[idx];
      const { px_per_1cm } = pf.size_faces[0];
      pf.cv_print_area.visible = false;
      const url = await makeResizedPrintableComposedImage(pf.designs, px_per_1cm, 'image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = `#${up.projection_id}_${up.id}_${pf.face_name}.png`;
      a.click();
      $.don_loader_end();
    }),
    $.on('click', '.product_face .design div.down button.edit', async function (e) {
      try {
        const ct = e.currentTarget;
        const upcs = go(ct, $closest('.up_c_ss'), box.sel);

        const {
          projection_id,
          up_id,
          _: { base_product, base_product_color, up_c_ss },
        } = upcs;

        const total_qty = go(
          up_c_ss,
          sumBy((up_cs) => up_cs.quantity),
        );

        const $pf = $closest('.product_face', ct);
        const pf = box.sel($pf);
        const product_face_idx = $attr('idx', $pf);
        const cid = go(ct, $closest('.design'), $attr('cid'));

        const pc = go(ct, $closest('.product_color'), box.sel);

        const shiboris_to_print_area_top = go(
          pc.product_faces2.value[product_face_idx].size_faces,
          map((size) => {
            const { base_product_size_id, start_point_cm: shibori_to_print_area_top } = size;
            return { base_product_size_id, shibori_to_print_area_top };
          }),
        );

        $.don_loader_start();

        const frame_title = `주문번호(${projection_id}) - 상품번호(${up_id}) - ${base_product.name}  - ${base_product_color.name} - ${pf.face_name}`;

        await openImageEditorFrame({
          title: frame_title,
          unique_frame_tag_for_cache: `${frame_title}-${cid}`,
          printable_file_info: {
            cid,
            projection_id,
            up_id,
            product_id: pc.id,
            base_product_face_id: pf.bpf_id,
            product_face_idx: product_face_idx,
            face_name: pf.face_name,
            total_qty,
            color_code: base_product_color.color_code,
            bpcf_img_dataurl: await DfImageEditorF.imageUrlToDataUrl({ url: pf.cv_bpcf.src }),
          },
          press_types: box.sel('press_types'),
          getImageEditableSourceInfo: async () => {
            const base_product_id = go(
              e.currentTarget,
              $closest('.up_item'),
              (up_item) => up_item && box.sel(up_item),
              sel('base_product_id'),
            );
            const is_ring = carved_bp_ids.includes(base_product_id);
            const pf_design = go(
              ct,
              $closest('.designs'),
              box.sel,
              find((d) => d.cid === cid),
            );
            const pf_sizes = pf?.size_faces;
            const { px_per_1cm } = go(
              ct,
              $.closest('.up_c_ss'),
              $find('.up_c_s_item.selected'),
              box.sel,
              sel('base_product_size_id'),
              (bps_id) => find((pf_size) => pf_size.base_product_size_id === bps_id)(pf_sizes),
            );

            const DPI = 300;
            const INCH_TO_CM = 2.54;
            const MULTIPLIER = 1;
            const dpi_convert_scale_factor = DPI / (px_per_1cm * INCH_TO_CM);
            const design_source = await getPrintableImageEditableSource({
              dpi: DPI,
              pf_designs: [pf_design],
              pixel_enhancement_scale: dpi_convert_scale_factor,
              multiplier: MULTIPLIER,
              shiboris_to_print_area_top_cm: shiboris_to_print_area_top,
              print_area_bound: (() => {
                const print_area = pf.cv_print_area;
                if (print_area) {
                  const { strokeWidth, width: w, height: h, top: t, left: l } = print_area;
                  const scale = dpi_convert_scale_factor * MULTIPLIER;
                  return {
                    width: (w - strokeWidth) * scale,
                    height: (h - strokeWidth) * scale,
                    top: (t + strokeWidth) * scale,
                    left: (l + strokeWidth) * scale,
                  };
                }
              })(),
            });

            if (is_ring) {
              design_source.data_url = await makeDataURLToBlack(design_source.data_url);
            }

            const { data_url, design_margin_x, multiplier, dpi, trim_offset_y } = design_source;

            return { data_url, design_margin_x, multiplier, dpi, trim_offset_y };
          },
        });
      } catch (e) {
        console.error(e);
        $.alert(JSON.stringify(e.message, null, 2));
      } finally {
        $.don_loader_end();
      }
    }),
    $.on('click', '.product_face .design .down button.down', async function (e) {
      try {
        $.don_loader_start();
        const base_product_id = go(
          e.currentTarget,
          $closest('.up_item'),
          (up_item) => up_item && box.sel(up_item),
          sel('base_product_id'),
        );
        const is_ring = carved_bp_ids.includes(base_product_id);
        await _p.go(
          e.currentTarget,
          $.closest('.design'),
          _p.add_arg(
            function (el_design) {
              const idx = $.attr(el_design, 'idx');
              return _p.go(
                $.closest(el_design, '.designs'),
                box.sel,
                G.mp.maker.reject_ai,
                reject((cv_obj) => cv_obj._data.clone_parent_cid),
                _p.filter((d) => d.visible),
                _p.v(idx),
              );
            },
            UtilS.wrapArr,
            (arr) => {
              const is_preview = go(e.currentTarget, $closest('.pc_preview'));
              if (!is_preview) {
                const pf = go(e.currentTarget, $.closest('.product_face'), box.sel);
                const pf_sizes = pf?.size_faces;
                const { px_per_1cm } = go(
                  e.currentTarget,
                  $.closest('.up_c_ss'),
                  $find('.up_c_s_item.selected'),
                  box.sel,
                  sel('base_product_size_id'),
                  (bps_id) => find((pf_size) => pf_size.base_product_size_id === bps_id)(pf_sizes),
                );
                return makePrintableComposedImage(arr, px_per_1cm);
              }
              return makePrintableComposedImage(arr);
            },
          ),
          async function (el_design, url) {
            if (url === 'cancel') return;
            if (!url) return alert('실패했습니다.');
            if (is_ring) url = await makeDataURLToBlack(url);
            const el_pf = $.closest(el_design, '.product_face');
            const up = box.sel($.closest(el_pf, '.up_item'));
            const pf = box.sel(el_pf);
            G.mp.maker.download(
              url,
              `주문번호_${up.projection_id}_상품번호_${up.id}_${pf.face_name}_${$.attr(el_design, 'idx')}`,
            );
          },
          $.don_loader_end,
        );
      } catch (e) {
        console.error(e);
        $.don_loader_end();
        $.alert('해상도를 줄여서 다시 시도해주세요~!');
      }
    }),
    $.on('click', '.partial-delivery', async ({ currentTarget }) => {
      if (!(await $.confirm('부분출고를 진행하시겠습니까?'))) {
        return;
      }
      $.don_loader_start();
      try {
        const is_partial_delivery = currentTarget.checked;
        const up = box.sel($closest('.up_item', currentTarget));
        await axios.post('/@api/user_product/partial_delivery', {
          is_partial_delivery,
          up_id: up.id,
        });

        const toggle_partial_delivery = (currentTarget) =>
          go(
            currentTarget,
            $closest('.prj_item_inner'),
            $find('.projection_sidebar'),
            $setAttr(['data-is_partial_delivery', is_partial_delivery]),
          );

        go(currentTarget, $closest('.partial-delivery-wrap'), $toggleClass('checked'));

        if (is_partial_delivery) {
          toggle_partial_delivery(currentTarget);
        } else {
          const is_all_siblings_unchecked = go(
            currentTarget,
            $closest('.up_list'),
            $findAll('.partial-delivery'),
            every((el) => !el.checked),
          );
          if (is_all_siblings_unchecked) toggle_partial_delivery(currentTarget);
        }
      } catch (e) {
        console.error(e);
      } finally {
        await makeDfProjectionListUpdate();
        $.don_loader_end();
      }
    }),
    $.on('click', '.holding_wow_order', async (e) => {
      e.preventDefault();
      const is_hold = e.currentTarget.checked;
      const msg = is_hold
        ? `주문을 보류하시겠습니까? 제작이 되지 않습니다.`
        : `주문 보류를 취소하시겠습니까?`;

      if (!(await $.confirm(msg))) {
        return;
      }
      $.don_loader_start();
      try {
        const { id, projection_id } = box.sel($closest('.up_item', e.currentTarget));
        const up = await axios.post('/@api/wow_press/toggle_order', {
          up_id: id,
          projection_id,
          is_hold,
        });
        e.currentTarget.checked = is_hold;
        go(e.currentTarget, $closest('.holding_order_wrap'), $toggleClass('checked'));
      } catch (e) {
        console.error(e);
      } finally {
        $.don_loader_end();
      }
    }),
    $.on('click', '.download_sop', workOrderSheetEventHandler),
    $.on('click', '.biz_requests', async ({ currentTarget: ct }) => {
      const biz_option_values = $data(ct);

      const request_memo = biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.REQ_MEMO];
      const ref_file_url = biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_URL];
      const ref_file_name = biz_option_values[BpOptionConstantS.BIZ_PF2_DATA_KEYS.FILE_REF_NAME];

      await Swal.fire({
        title: '비즈 상품 고객 요청 정보',
        width: 900,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: '닫기',
        html: html`
          <div id="biz_product_customer_request_info">
            <div class="item req_memo">
              <div class="header">
                <span>고객 요청 메모</span>
              </div>
              <div class="body">
                <textarea rows="4" cols="80" readonly>
                ${UtilStringS.isEmNil(request_memo) ? '메모 없음' : UtilS.escape(request_memo)}</textarea
                >
              </div>
            </div>
            <div class="item ref_file">
              <div class="header">
                <span>참조 시안 파일</span>
              </div>
              <div class="body">
                <button class="download">
                  ${UtilStringS.isEmNil(ref_file_url) ? '파일 없음' : UtilS.escape(ref_file_name)}
                </button>
              </div>
            </div>
          </div>
        `,
        didOpen: (el) => {
          go(
            el,
            $delegate('click', '.ref_file button.download', () => {
              if (UtilStringS.isEmNil(ref_file_url)) return;

              downloadUrl({ url: ref_file_url, file_name: ref_file_name });
            }),
          );
        },
      });
    }),
    // $.on('click', 'button.print_upcs_labels', DfLglF.handleUpcsLabel),
    $.on('click', 'button.print_sku_labels', DfLabelF.handleSkuLabelPrint),
    $.on('click', '.print_bp_labels', DfBpLabelF.event),
    $.on('click', '.copy_cv_text_image_data_text_info_text', async (e) => {
      if (!navigator.clipboard.writeText)
        return $.alert('클립보드 기능을 사용 할수 없는 브라우저입니다. 크롬 최신버전을 이용해주세요.');
      $.don_loader_start();
      await delay(100, () => {});
      const copy_text = go(e.currentTarget, $closest('.design'), box.sel, sel('_data.text_info.text'));
      await navigator.clipboard.writeText(copy_text);
      $.don_loader_end();
    }),
    $.on('click', '.current_item_order_status', async ({ currentTarget }) => {
      const order_process_step = box.sel(currentTarget);

      const is_cancelable = order_process_step?.can_cancel;

      if (is_cancelable == null) {
        await UtilAlertF.error({ title: '상태 확인 오류', msg: '개발팀 문의' });
        return;
      }
      await Swal.fire({
        icon: is_cancelable ? 'success' : 'error',
        title: `취소 ${is_cancelable ? '가능' : '불가능'}`,
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: true,
        confirmButtonText: '확인',
      });
    }),
    $.on('change', '.pf_loc_opt_cs', async ({ currentTarget: ct }) => {
      try {
        $.don_loader_start();
        const pf_el = go(ct, $closest('.product_face'));
        const upc_item = go(ct, $closest('.up_c_item'), box.sel);

        const face_idx = pf_el.getAttribute('idx');

        await axios.post('/@api/user_product/pf_loc_opt_cs', {
          projection_id: upc_item.projection_id,
          up_id: upc_item.id,
          face_idx,
          is_requested: ct.checked,
        });
        $.don_loader_end();
      } catch (err) {
        $.don_loader_end();
        console.error(err);
        $.alert(UtilF.getErrMsg(err));
      }
    }),
    $.on('click', '.copy_file_name', async (e) => {
      if (!navigator.clipboard.writeText)
        return $.alert('클립보드 기능을 사용 할수 없는 브라우저입니다. 크롬 최신버전을 이용해주세요.');
      $.don_loader_start();
      const up_c_item_el = go(e.currentTarget, $closest('.up_c_item'));
      const prj_item = go(e.currentTarget, $closest('.prj_item'), box.sel);
      const up_c = box.sel(up_c_item_el);
      const projection_id = prj_item.id;
      const merged_type = prj_item.merged_type;
      const base_product = up_c._.base_product;
      const base_product_color = up_c._.base_product_color;
      const up_c_ss = go(
        up_c._.up_c_ss,
        filter((up_c_s) => up_c_s.quantity > 0),
      );
      const qunantity = go(
        up_c_ss,
        sumBy((up_c_s) => up_c_s.quantity),
      );
      let { template_meta } = await $.get('/@api/prerequisite_maker/base_product_size', {
        id: up_c.base_product_size_id,
      });
      let copy_text;
      template_meta = Array.isArray(template_meta) ? template_meta[0] : template_meta;
      if (!template_meta) {
        const header = `${base_product_color.name ? `<${base_product_color.name}> ` : ``}${
          base_product.name
        }`;
        const body = go(
          up_c_ss,
          map((up_c_s) => `${up_c_s['_name'] || up_c_s._.base_product_size.name}   /   ${up_c_s.quantity}`),
          join('\n'),
        );
        copy_text = header + '\n' + body;
      } else if (template_meta.text?.copy_text) {
        go(up_c_ss[0], (up_c_s) => {
          const title = NewMakerPrintResultF.makeFileName({
            up_c,
            base_product,
            up_c_s,
            merged_type,
            base_product_color,
            selected_option_group: up_c._.product_color._.selected_option_group,
          });
          copy_text = makeTitleByKeys(title, template_meta.text.copy_text);
        });
      } else {
        copy_text = `${projection_id}_${base_product.name}_${qunantity}ea`;
      }

      await navigator.clipboard.writeText(copy_text);
      $.don_loader_end();
    }),
    $.on('click', '.printable_file_download', async (e) => {
      try {
        $.don_loader_start();
        e.preventDefault();
        const ct = e.currentTarget;
        const url = ct.href;
        const name = ct.download;

        let resource;
        const is_ai_file = e.currentTarget.dataset.is_ai === 'true';

        if (is_ai_file) {
          resource = url;
        } else {
          const { meta } = await $.get('/@api/printable_file/metadata', { url });
          resource =
            meta?.space === 'b-w' ? `/@api/printable_file/convert_to_rgb?url=${url}&name=${name}` : url;
        }

        function extractFileExtension(filename) {
          return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
        }

        const upc = box.sel($.closest(ct, '.up_c_item'));
        const pf = box.sel($.closest(ct, '.product_face'));

        const quantity_tobe_printed_per_file = sumBy(sel('quantity'), upc._.up_c_ss);

        /* 인쇄용 파일명 규칙 -> 조판 프로그램과 연동되어 있으므로 수정시 마플팀 문의 필요
         *  {주문번호}-{상품번호 upc_id}-{면위치}-{인쇄매수}.extension
         * */
        const download_filename = `${upc.projection_id}@${upc.id}-${
          pf.face_name
        }@${quantity_tobe_printed_per_file}${is_ai_file ? '.ai' : ''}`;

        const download_link = document.createElement('a');
        const extension = extractFileExtension(resource);

        /* 주문 내 클릭한 press type 에 해당 하는 모든 파일 일괄 다운로드 */
        if (e.altKey && e.shiftKey) {
          const getFilePressTypeFromFileEl = (file_el) => {
            const selected_press_type_el = $find('.select_press_type .selected', file_el);
            return selected_press_type_el && selected_press_type_el.textContent;
          };

          const press_type_clicked_file = go(ct, $closest('.file'), getFilePressTypeFromFileEl);

          const up_item_list_el = $closest('.up_list', ct);
          const projection_id = go(ct, $closest('.prj_item'), box.sel).projection_id;
          const zip = new JSZip();

          await go(
            up_item_list_el,
            $findAll('.up_item'),
            mapC(async (up_item_el) => {
              const up_item = box.sel(up_item_el);
              const up_id = up_item.id;
              let folder;
              await go(
                up_item_el,
                $findAll('.file'),
                filterC((file_el) => {
                  const file_press_type = getFilePressTypeFromFileEl(file_el);
                  return file_press_type === press_type_clicked_file;
                }),
                mapC(async (file_el) => {
                  const file = box.sel(file_el);
                  let url = file.url;
                  const is_ai = !!file.ai_url;

                  if (is_ai) {
                    url = file.ai_url;
                  } else {
                    if (url == null) return;
                    const { meta } = await $.get('/@api/printable_file/metadata', { url });
                    /* b-w color space 에서 픽셀 array 길이가 다르므로 color space 를 rgb 로 변경해 줘야 정상적으로 이미지 처리 가능 */
                    url =
                      meta.space === 'b-w'
                        ? `/@api/printable_file/convert_to_rgb?url=${url}&name=${name}`
                        : url;
                  }
                  return {
                    file_el,
                    url,
                    response: await axios.get(url + '?hello=world', { responseType: 'arraybuffer' }),
                  };
                }),
                compactC,
                /* up 단위로 폴더 구분하는 것은 작업자의 니즈가 있을 때 적용 */
                // tap((file_els) => {
                //   if (file_els.length > 0) {
                //     /* 저장할 파일이 있으면 up_id 로 폴더 구조 생성 */
                //     folder = zip.folder(`상품(${up_id})`);
                //   }
                // }),
                mapC(({ file_el, url, response }) => {
                  const up_c_item = go(file_el, $closest('.up_c_item'), box.sel);

                  const base_product = up_c_item._.base_product;
                  const base_product_color = up_c_item._.base_product_color;
                  const pf = go(file_el, $closest('.product_face'), box.sel);

                  const product_name = base_product.name;
                  const color_name = base_product_color.name;
                  const face_name = pf.face_name;

                  const file_name = `${color_name}_${face_name}_${product_name}.${extractFileExtension(url)}`;

                  const { data: buffer, headers } = response;
                  const file_blob = new Blob([new Uint8Array(buffer)], { type: headers['content-type'] });
                  folder
                    ? folder.file(file_name, file_blob)
                    : zip.file(`상품(${up_id})_${file_name}`, file_blob);
                }),
                takeAllC,
              );
            }),
          );

          const blob = await zip.generateAsync({ type: 'blob' });
          const zip_file_name = `주문(${projection_id})-생산(${press_type_clicked_file})`;

          saveAs(blob, zip_file_name);
          return;
        }

        if (e.altKey || extension === 'svg') {
          /* metaKey 를 누른 상태에서 mouse click 하면 즉시 파일로 다운로드 */
          /* querystring 을 추가하면 cors 에러 없이 동작 */
          const response_blob = await fetch(`${resource}?hello=world`).then((res) => res.blob());
          download_link.href = URL.createObjectURL(response_blob);
        } else {
          /* metaKey 누르지 않고 mouse click 하면 tab 으로 preview */
          download_link.href = resource;
          download_link.target = '_blank';
        }
        download_link.download = download_filename;
        download_link.click();
        URL.revokeObjectURL(download_link.href);
      } catch (e) {
        console.error(e);
      } finally {
        $.don_loader_end();
      }
    }),
    $.on('click', '.product_face .design .original_down', function (e) {
      $.don_loader_start();

      _p.go(
        e.currentTarget,
        $.closest('.design'),
        _p.add_arg(
          function (el_design) {
            const idx = $.attr(el_design, 'idx');
            return _p.go(
              $.closest(el_design, '.designs'),
              box.sel,
              G.mp.maker.reject_ai,
              _p.filter((d) => d.visible),
              _p.v(idx),
            );
          },
          function (cv_object) {
            const url = e.shiftKey
              ? cv_object._data.image_url
              : cv_object._data.image_original_url || cv_object._data.image_url;
            return url
              ? G.to_original(url)
              : Promise.reject(_p.go($.alert('원본 이미지가 없습니다.'), _p.noop, $.don_loader_end));
          },
        ),
        function (el_design, url) {
          window.open(url);
        },
        $.don_loader_end,
      );
    }),
    $.on('click', '.pdf-issue-report', async (e) => {
      const { is_cancel_req, is_canceled } = go(e.currentTarget, $closest('.prj_item'), box.sel);
      if (is_cancel_req || is_canceled) {
        return;
      }
      const up_item_el = go(e.currentTarget, $closest('.up_item'));
      await MuiF.openFrame(DfFileIssueModalMuiF.frame, (f, p, [t]) => {
        f.closed = async (e, result) => {
          if (result?.status) {
            await makeDfProjectionListUpdate();
          }
        };
        t.makeData = () => ({
          history: go(up_item_el, box.sel, sel('_.pdf_file')),
        });
      });
    }),
    $.on('click', '.pdf-issue-reorder-waiting', async (e) => {
      const { is_cancel_req, is_canceled } = go(e.currentTarget, $closest('.prj_item'), box.sel);
      if (is_cancel_req || is_canceled) {
        return;
      }
      const up_item_el = go(e.currentTarget, $closest('.up_item'));
      const { id: user_product_id } = go(up_item_el, box.sel);
      await MuiF.openFrame(SimpleModalMuiF.frame, (f, p, [t]) => {
        f.appended = (frame_el) => {
          go(frame_el, $find('.don_wrapper'), $setCss({ width: '10%', height: '20%' }));
        };
        p.hide_frame_button_type = 'X';
        t.appended = async (tab) => {
          go(
            tab,
            $delegate('click', '.footer .file-reorder-btn', async (e) => {
              const file_el = go(
                e.delegateTarget,
                $find('.pdf-issue-report__reorder-file input[type="file"]'),
              );
              const file = file_el.files[0];
              if (!user_product_id) {
                return $.alert('상품번호를 찾지 못했습니다.');
              }
              if (!file) {
                return $.alert('선택된 파일이 없습니다.');
              }
              $.don_loader_start();

              try {
                const result = await PdfF.uploadPdfFile({ file });
                if (result.success) {
                  const [{ id: pdf_printing_file_id }] = result.data;
                  await axios.post(`/@api/wow_press/file_upload`, {
                    user_product_id,
                    pdf_printing_file_id,
                  });
                  $.don_loader_end();
                  await makeDfProjectionListUpdate();
                  MuiF.closeFrame();
                } else {
                  $.alert(result.message);
                  $.don_loader_end();
                }
              } catch (e) {
                $.alert(e?.message || '문제 발생');
                $.don_loader_end();
              }
            }),
          );
        };
        t.makeData = () => ({
          body: html` <div class="pdf-issue-report__reorder-file" style="text-align: center">
            <input name="file" type="file" />
          </div>`,
          footer: html` <div class="footer">
            <button class="file-reorder-btn" type="button">파일 재업로드</button>
          </div>`,
        });
      });
    }),
  );

  G.df.projection.detail.img_thumbnail_error = img_thumbnail_error;
  G.df.projection.detail.dci_init = dci_init;

  G.df.projection.detail.up_item_init = _p.tap(
    $.on2('click', '.show_all_design', function (e) {
      return _p.go(e.currentTarget, $.closest('.product_face'), $.toggle_class('showed_all_design'));
    }),
    $.on2(
      'click',
      '.up_c_s_item:not(.selected):not(.total) .size, .up_c_s_item:not(.selected):not(.total) .quantity',
      function (e) {
        const ct = e.currentTarget;

        const selected_bp_size_id = box.sel(ct).base_product_size_id;

        /* base_product_size_id 선택 변경시 -> shibori 수치 업데이트
         *  [갱신해 줄 곳]
         *  1. 디자인의 product_face - 전체 디자인 사이즈의 시보리 수치 업데이트
         *  2. 인쇄용 시안의 product face
         * */

        /* 1. 디자인 시보리 수치 업데이트 */
        const pf_design_els = go(ct, $closest('.up_c_list'), $findAll('.product_face:not(.printable)'));
        go(
          pf_design_els,
          each((pf_design_el) => {
            const span_el = $find('.justify-size-info span.total-design-shibori', pf_design_el);
            if (span_el) {
              const shibori_cm = calculateShiboriForDesign({
                pf: box.sel(pf_design_el),
                base_product_size_id: selected_bp_size_id,
              });
              shibori_cm && (span_el.textContent = `${shibori_cm.toFixed(1)} cm`);
            }
          }),
        );

        /* 2. printable file 시보리 수치 업데이트 */
        const pf_printable_els = go(ct, $closest('.up_c_list'), $findAll('.printable .product_face'));
        go(
          pf_printable_els,
          each((pf_printable_el) => {
            go(
              $findAll('.file', pf_printable_el),
              each((file_el) => {
                const file = box.sel(file_el);
                const pf = box.sel(pf_printable_el);
                const shibori_cm = calculatePrintableFileShiboriCm({
                  pf,
                  file,
                  bp_size_id: selected_bp_size_id,
                });
                if (shibori_cm) {
                  const span_el = go(file_el, $find('.justify-size .size-shibori span'));
                  span_el && (span_el.textContent = `${shibori_cm.toFixed(1)} cm`);
                }
              }),
            );
          }),
        );

        const item_el = $closest('.up_c_s_item', ct);
        return _p.go(
          item_el,
          _p.tap($.siblings('.selected'), $.remove_class('selected')),
          $.add_class('selected'),
          $.closest('.up_item'),
          G.mp.maker.draw_product_face_in_ups,
          _p.wait(200),
        );
      },
    ),

    dci_init,
    $.on('click', '.product_color .product_face .thumb', function (e) {
      const el_pc = _go(e.currentTarget, $.closest('.product_color'));
      const face_idx = _go(e.currentTarget, $.closest('.product_face'), $.attr('idx'), parseInt);
      G.df.projection.pc_preview.frame(
        box.sel(el_pc),
        _go(el_pc, $.closest('.up_item'), box.sel),
        $.is(el_pc, '.printable'),
        _go(e.currentTarget, $.closest('.up_c_item'), $.find1('.up_c_s_item.selected .size'), $.text),
        face_idx,
      );
    }),
    $.on('click', '.product_color .product_face .design .img', function (e) {
      const el_pc = _go(e.currentTarget, $.closest('.product_color'));
      const face_idx = _go(e.currentTarget, $.closest('.product_face'), $.attr('idx'), parseInt);
      const design_idx = _go(e.currentTarget, $.closest('.design'), $.attr('idx'), parseInt);
      G.df.projection.pc_preview.frame(
        box.sel(el_pc),
        _go(el_pc, $.closest('.up_item'), box.sel),
        $.is(el_pc, '.printable'),
        _go(e.currentTarget, $.closest('.up_c_item'), $.find1('.up_c_s_item.selected .size'), $.text),
        face_idx,
        design_idx,
      );
    }),
    $.on('click', '.product_color .printable_files .file .img', function (e) {
      const el_pc = _go(e.currentTarget, $.closest('.product_color'));
      const face_idx = _go(e.currentTarget, $.closest('.product_face'), $.attr('idx'), parseInt);
      const design_idx = _go(e.currentTarget, $.closest('.file'), $.attr('idx'), parseInt);
      G.df.projection.pc_preview.frame(
        box.sel(el_pc),
        _go(el_pc, $.closest('.up_item'), box.sel),
        $.is(el_pc, '.printable'),
        _go(e.currentTarget, $.closest('.up_c_item'), $.find1('.up_c_s_item.selected .size'), $.text),
        face_idx,
        design_idx,
      );
    }),
    $.on2('click', '.product_color .printable_files .remove_all', function (e) {
      const el_file = _p.go(e.currentTarget, $.closest('.file'));
      const file_id = _p.go(el_file, $.attr('_id'));
      const created_at = _p.go(el_file, $.attr('_created_at'));
      const el_up = _go(e.currentTarget, $.closest('.up_item'));
      const up = box.sel(el_up);
      const { projection_id } = up;
      const el_don_page = $.closest(e.currentTarget, '.don_page');
      const el_don_wrapper = $.find1(el_don_page, '>.don_wrapper');
      $.attr(el_don_wrapper, { prev_scroll_top: $.scroll_top(el_don_wrapper) });
      $.don_loader_start();
      return _p.go(
        { file_id, created_at, projection_id },
        _p($.post, '/@api/printable_files/remove_all2'),
        function () {
          return render_products_tab(el_up);
        },
        _p.noop,
        $.don_loader_end,
      );
    }),
  );

  G.df.projection.detail.open = async function (
    projection,
    tab_name = 'products',
    bk,
    projection_payment_id,
  ) {
    if (
      !box.sel('is_user->_->policies->mp_worker_policy') &&
      !box.sel('is_user->_->policies->outsourcing_worker_policy')
    )
      return;
    box.set('df/projection/detail->projection', {});

    return $.frame.open(
      {
        title: `<a href="/projection/detail/${projection.id}" target="_blank">주문서 상세 보기 [${
          projection.lang == 'kr' ? '국문' : '영문'
        }]</a>`,
        frame_name: 'projection.detail',
        frame_tag: projection.id,
        projection_id: projection.id,
        collabo_type: projection.collabo_type,
        projection_type: projection.type,
        bk,
        projection_payment_id,
        prev_frame_show: !!projection_payment_id,
      },
      {
        tabs: [
          {
            tab_name,
            selected: true,
          },
        ],
      },
    );
  };

  $.frame.defn_frame({
    el_class: 'projection',
    frame_name: 'df.projection.detail.edit_sizes',
    page_name: 'df.projection.detail.edit_sizes',
    is_modal: true,
    always_remove: true,
    appending: _p.pipe(
      $.on('click', function (e) {
        if (e.target == e.delegateTarget) return $.frame.close();
      }),
    ),
  });

  function open_edit_sizes(up, up_c, el = _p.last($('.projection_detail .don_tab[tab_name="products"]'))) {
    const projection_id = up.projection_id;
    const bpss = up_c._.base_product_color._.base_product_sizes;
    const indexed_up_c_ss = _p.index_by(up_c._.up_c_ss, 'base_product_size_id');
    const data_func = _p.c({
      title: `상품 번호 #${up_c.up_id} - ${up._.base_product.name} <${
        up_c._.base_product_color.name2 || up_c._.base_product_color.name
      }>`,
      bpss: _p.map(bpss, ({ id, name, _is_not_stock, is_public }) => ({
        id,
        name,
        _is_not_stock,
        is_public,
        quantity: _p.v(indexed_up_c_ss, `${id}.quantity`) || 0,
      })),
    });
    return new Promise(function (resolve) {
      setTimeout(function () {
        $.frame.open(
          {
            frame_name: 'df.projection.detail.edit_sizes',
            closing: async function (X, need_render) {
              need_render && el && (await render_products_tab(el));
              resolve(need_render);
            },
          },
          { tabs: [{ data_func, up_c, projection_id }] },
        );
      }, 300); // TODO 300 동안 로딩
    });
  }

  $.frame.defn_page({
    page_name: 'df.projection.detail.edit_sizes',
    hide_frame_button_type: 'X',
    title: '사이즈별 수량',
    tabs: [
      {
        tab_name: 'df.projection.detail.edit_sizes',
        template: ({ title, bpss }) => pug`
        h4 ${title}
        table.sizes ${_p.sum(
          bpss,
          (bps) => pug`
          tr.size[_id="${bps.id}"]
            th ${bps.name}${bps._is_not_stock || !bps.is_public ? '<br>재고없음' : ''}
            td
              input[type="number" min="0" value="${bps.quantity}"]
        `,
        )}
        .options
          button[type="button"].cancel 취소
          button[type="button"].reset 모두 0개로 만들기
          button[type="button"].submit 반영하기
      `,
        appending: _p.pipe(
          $.on2('click', '.cancel', function () {
            return $.frame.close();
          }),
          $.on2('click', '.reset', function (e) {
            return _p.go(e.delegateTarget, $.find('table tr input'), $.val(0));
          }),
          $.on2('click', '.submit', async function (e) {
            $.don_loader_start();
            const up_c = e.delegateTarget.tab_opt.up_c;
            const projection_id = e.delegateTarget.tab_opt.projection_id;
            return _p.go(
              e.delegateTarget,
              $.find('table tr'),
              _p.map((tr) => ({
                base_product_size_id: $.attr(tr, '_id'),
                quantity: Math.max(0, _p.go(tr, $.find1('input'), $.val, parseInt) || 0),
              })),
              ifElse(
                async () => {
                  return await $.confirm(
                    '<strong>상품종류</strong>와 <strong>수량</strong>이<br>정확히 기입 되었는지<br><strong>더블체크</strong> 되었나요? 🤔',
                  );
                },
                pipe(
                  async (up_c_ss_tobe_updated) => {
                    await axios
                      .post('/@api/user_product/up_c/edit_sizes', {
                        up_c_id: up_c.id,
                        up_c_ss_tobe_updated,
                        projection_id,
                      })
                      .then(() => {
                        UtilAlertF.success({ title: '수량 반영 완료' });
                      })
                      .catch(async (e) => {
                        $.don_loader_end();
                        if (e.response?.data) {
                          const data = e.response.data;

                          if (data?.is_tech_pack) {
                            // 테크팩 상품 PDF 때문에 에러 발생한 경우 force 로 처리할 것인지 질의

                            if (
                              await UtilAlertF.confirm({
                                title: 'PDF 생성 경고',
                                msg: `${data.message}\n 그래도 강제로 진행하시겠습니까?`,
                              })
                            ) {
                              try {
                                $.don_loader_start();
                                const result = (
                                  await axios.post('/@api/user_product/up_c/edit_sizes', {
                                    is_tech_pack_force: true,
                                    up_c_id: up_c.id,
                                    up_c_ss_tobe_updated,
                                    projection_id,
                                  })
                                ).data;

                                if (result?.success) {
                                  UtilAlertF.success({ title: '수량 반영 완료' });
                                } else {
                                  await UtilAlertF.error({
                                    title: '수량 반영 실패',
                                    msg: '개발팀 문의',
                                  });
                                }
                              } catch (err) {
                                console.error(err);
                                await UtilAlertF.error({
                                  title: '수량 반영 실패',
                                  msg: '개발팀 문의',
                                });
                              } finally {
                                $.don_loader_end();
                              }
                            }
                          } else {
                            await UtilAlertF.error({
                              title: '수량 반영 실패',
                              msg: typeof data === 'string' ? data : (data?.message ?? '알수 없는 오류'),
                            });
                          }
                        }
                      });
                  },
                  _p.c(true),
                  $.frame.close,
                  _p.noop,
                  $.don_loader_end,
                ),
                () => {
                  $.don_loader_end();
                },
              ),
            );
          }),
        ),
      },
    ],
  });

  $.frame.defn_frame({
    el_class: 'projection',
    frame_name: 'df.projection.detail.add_color',
    page_name: 'df.projection.detail.add_color',
    is_modal: true,
    always_remove: true,
    appending: __(
      $.on('click', function (e) {
        if (e.target == e.delegateTarget) return $.frame.close();
      }),
      $.on2('click', '.cancel', function () {
        return $.frame.close();
      }),
      $.on2('click', 'tr button', async function (e) {
        $.don_loader_start();

        const up = e.delegateTarget.frame_opt.up;
        const up_c = e.delegateTarget.frame_opt.up_c;
        const { projection_id } = up;
        const base_product_color_id = _p.go(e.currentTarget, $.closest('tr'), $.attr('_id'));
        const up_c2 =
          _p.find(up._.up_cs, (up_c) => up_c.base_product_color_id == base_product_color_id) ||
          (await $.post('/@api/user_product/add_color', {
            up_c_id: up_c.id,
            base_product_color_id,
          }));
        const bpss = up_c2._.base_product_color._.base_product_sizes;

        await $.frame.add_page({
          page_name: 'df.projection.detail.edit_sizes',
          tabs: [
            {
              projection_id,
              up_c: up_c2,
              data_func: _p.c({
                title: `상품 번호 #${up_c2.up_id} - ${up._.base_product.name} <${
                  up_c2._.base_product_color.name2 || up_c2._.base_product_color.name
                }>`,
                bpss: _p.map(bpss, ({ id, name, is_public, _is_not_stock }) => ({
                  id,
                  name,
                  is_public,
                  _is_not_stock,
                  quantity: 0,
                })),
              }),
            },
          ],
        });

        $.don_loader_end();
      }),
    ),
  });

  $.frame.defn_page({
    page_name: 'df.projection.detail.add_color',
    hide_frame_button_type: 'X',
    title: '색상 추가',
    tabs: [
      {
        tab_name: 'df.projection.detail.add_color',
        template: ({ title, bpcs }) => pug`
        h4 ${title}
        table.colors ${_p.sum(
          bpcs,
          (bpc) => pug`
          tr.size[_id="${bpc.id}"]
            th[style="background-color: ${bpc.color_code}"]
            th ${bpc.name2 || bpc.name}
            td ${
              _p.every(bpc._.base_product_sizes, '_is_not_stock')
                ? '재고없음'
                : '<button type="button">선택</button>'
            }
        `,
        )}
        .options
          button[type="button"].cancel 취소
      `,
      },
    ],
  });
})({});
